import {
  Button,
  Form,
  InputSelect,
  InputText,
  PageTitle,
} from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import {
  faChartBar,
  faDollarSign,
  faStore,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map, range } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { useCallback, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

import {
  KpiData,
  useKpiDataLazyQuery,
  useVendorByDateCreatedLazyQuery,
  useVendorCountQuery,
} from 'lib/graphql';
import { KpiCalcs, kpiCalcs } from 'lib/kpiCalcs';

import KPIView, { KPIViewProps } from 'views/kpi-view';

interface KPIForm {
  interval: 'day' | 'week' | 'month' | 'quarter';
  graphType: KPIViewProps['graphType'];
  startDate: string;
  endDate: string;
}

const INITIAL_INTERVAL: KPIForm['interval'] = 'week';
const DATE_FORMAT = 'yyyy-MM-DD';

export default function KPI() {
  const enabledVendorsCount = useVendorCountQuery({
    variables: { filter: { enabled: true } },
  });

  const [getVendors, getVendorsQuery] = useVendorByDateCreatedLazyQuery();

  const [getData, { loading }] = useKpiDataLazyQuery();

  const [graphType, setGraphType] = useState<KPIViewProps['graphType']>('area');
  const [kpis, setKpis] = useState<KpiCalcs>();

  const getIntervalOptions = useCallback(
    (
      values: KPIForm,
    ): {
      startOptions: { value: string; label: string }[];
      endOptions: { value: string; label: string }[];
    } => {
      const intervalRange: number[] = range(0, 10, 1);
      return {
        startOptions: map(intervalRange, (r) => {
          const d = moment()
            .startOf(values.interval)
            .subtract(r, values.interval)
            .format(DATE_FORMAT);
          return { label: d, value: d };
        }),
        endOptions: map(intervalRange, (r) => {
          const d = moment()
            .startOf(values.interval)
            .subtract(r - 1, values.interval)
            .subtract(1, 'day')
            .format(DATE_FORMAT);
          return { label: d, value: d };
        }),
      };
    },
    [],
  );

  return (
    <div>
      <PageTitle title="KPIs" />
      <Container>
        <Form<KPIForm>
          initialValues={{
            graphType,
            interval: INITIAL_INTERVAL,
            startDate: moment()
              .subtract(2, INITIAL_INTERVAL)
              .startOf(INITIAL_INTERVAL)
              .format(DATE_FORMAT),
            endDate: moment()
              .add(1, INITIAL_INTERVAL)
              .startOf(INITIAL_INTERVAL)
              .subtract(1, 'day')
              .format(DATE_FORMAT),
          }}
          onSubmit={async (values) => {
            setGraphType(values.graphType);
            const [kpiResult, vendorsResult] = await Promise.all([
              getData({
                variables: {
                  start_date: values.startDate,
                  end_date: values.endDate,
                },
              }),
              getVendors({
                variables: {
                  date_created_start: new Date(values.startDate),
                  date_created_end: new Date(values.endDate),
                },
              }),
            ]);
            return setKpis(
              kpiCalcs(
                (kpiResult.data?.kpiData as KpiData[]) || [],
                values.interval,
                vendorsResult.data?.vendorMany || [],
                enabledVendorsCount.data?.vendorCount || 1,
              ),
            );
          }}
        >
          {(props) => (
            <Row md={4} lg={5} className="align-items-end g-3">
              <Col>
                <InputSelect
                  label="Interval"
                  options={['Day', 'Week', 'Month', 'Quarter']}
                  required
                />
              </Col>
              {props.values.interval === 'day' ? (
                <>
                  <Col>
                    <InputText label="Start Date" type="date" required />
                  </Col>
                  <Col>
                    <InputText label="End Date" type="date" required />
                  </Col>
                </>
              ) : (
                <>
                  <Col>
                    <InputSelect
                      label="Start Date"
                      options={getIntervalOptions(props.values).startOptions}
                      required
                    />
                  </Col>
                  <Col>
                    <InputSelect
                      label="End Date"
                      options={getIntervalOptions(props.values).endOptions}
                      required
                    />
                  </Col>
                </>
              )}
              <Col>
                <InputSelect
                  label="Graph Type"
                  options={['Line', 'Area', 'Bar', 'Bubble']}
                />
              </Col>
              <Col>
                <Button
                  content="Get KPIs"
                  icon={faChartBar}
                  type="submit"
                  isLoading={loading || getVendorsQuery.loading}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Container>
      <hr />
      <Container>
        {kpis && (
          <>
            <Card className="mb-3">
              <Card.Header>
                <FontAwesomeIcon
                  icon={faDollarSign}
                  className="text-primary me-2"
                />
                Sales
              </Card.Header>
              <Card.Body>
                <Row xs={1} sm={1} md={2} lg={3} className="g-3">
                  <Col>
                    <KPIView
                      title={kpis.totalSales.label}
                      data={[kpis.totalSales]}
                      formatter={formatToCurrency}
                      graphType={graphType}
                      description="Total sales $ during the period."
                    />
                  </Col>
                  <Col>
                    <KPIView
                      title={kpis.sales.label}
                      data={[kpis.sales]}
                      formatter={(v) => numeral(v).format('0,0')}
                      graphType={graphType}
                      description="# of sales during the period."
                    />
                  </Col>
                  <Col>
                    <KPIView
                      title={kpis.aov.label}
                      data={[kpis.aov]}
                      formatter={formatToCurrency}
                      graphType={graphType}
                      description="Average order value during the period."
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Header>
                <FontAwesomeIcon icon={faUser} className="text-primary me-2" />
                Customers
              </Card.Header>
              <Card.Body>
                <Row xs={1} sm={1} md={2} lg={3} className="g-3">
                  <Col>
                    <KPIView
                      title={kpis.newCustomers.label}
                      data={[kpis.newCustomers]}
                      formatter={(v) => numeral(v).format('0,0')}
                      graphType={graphType}
                      description="# of customers who made their first purchase during the period."
                    />
                  </Col>
                  <Col>
                    <KPIView
                      title={kpis.repeatCustomers.label}
                      data={[kpis.repeatCustomers]}
                      formatter={(v) => numeral(v).format('0,0')}
                      graphType={graphType}
                      description="# of customers who made a repeat purchase during the period."
                    />
                  </Col>
                  <Col>
                    <KPIView
                      title={kpis.vendorsWithSales.label}
                      data={[kpis.vendorsWithSales]}
                      formatter={(v) => numeral(v).format('0,0')}
                      graphType={graphType}
                      description="# of vendors with at least 1 sale during the period."
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="mb-3">
              <Card.Header>
                <FontAwesomeIcon icon={faStore} className="text-primary me-2" />
                Vendors
              </Card.Header>
              <Card.Body>
                <Row xs={1} sm={1} md={2} lg={3} className="g-3">
                  <Col>
                    <KPIView
                      title={kpis.newVendors.label}
                      data={[kpis.newVendors]}
                      formatter={(v) => numeral(v).format('0,0')}
                      graphType={graphType}
                      description="New vendor signups during the period."
                    />
                  </Col>
                  <Col>
                    <KPIView
                      title={kpis.activeVendors.label}
                      data={[kpis.activeVendors]}
                      formatter={(v) => numeral(v).format('0,0')}
                      graphType={graphType}
                      description="# of active vendors during the period."
                    />
                  </Col>
                  <Col>
                    <KPIView
                      title={kpis.salesPerVendor.label}
                      data={[kpis.salesPerVendor]}
                      formatter={(v) => numeral(v).format('0,0.0')}
                      graphType={graphType}
                      description="# of sales per active vendors."
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* <Card className="mb-3">
              <Card.Header>
                <FontAwesomeIcon icon={faStore} className="text-primary me-2" />
                Reporting
              </Card.Header>
              <Card.Body>
                <Row xs={1} sm={1} md={2} lg={3} className="g-3">
                  <Col>
                    <KPIView
                      title="KPIs"
                      data={[
                        kpis.newVendors,
                        kpis.activeVendors,
                        kpis.salesPerVendor,
                      ]}
                      formatter={(v) => numeral(v).format('0,0')}
                      graphType={graphType}
                      description=""
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card> */}
          </>
        )}
      </Container>
    </div>
  );
}
