import { ApolloError } from '@apollo/client';
import { Button, InputText, Table } from '@farmshare/ui-components';
import { formatToCurrency, isArrayNullOrEmpty } from '@farmshare/utils';
import {
  faCircleCheck,
  faPlus,
  faTrash,
  // faDolly,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrayHelpers, FieldArray, FormikProps } from 'formik';
import { includes, map, sortBy, startCase, sumBy } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Accordion, Alert, Card, Col, Row } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';

import {
  Shipment,
  ShipmentRate,
  useShipmentGetRatesMutation,
} from 'lib/graphql';

// import { AccessPoint } from './access-point';

export interface LabelModalForm {
  requestedDate: string;
  shipDate?: string;
  chosenRate?: string;
  vendorPickupDays?: string;
  vendorPrefersDropoff?: string;
  selectedShipDay?: string;
  chosenAccessPoint?: string;
  carrier?: string;
  packages?: {
    external_id: string;
    weight: number;
    length: number;
    width: number;
    height: number;
  }[];
}

interface LabelModalProps {
  shipment: Shipment;
  orderId: number;
  formProps: FormikProps<LabelModalForm>;
}

export function LabelModal({
  shipment,
  orderId,
  formProps: { isValid, setFieldError, setFieldValue, values },
}: LabelModalProps) {
  const [activeKey, setActiveKey] = useState<AccordionEventKey>('0');

  const [getRates, rateMutation] = useShipmentGetRatesMutation();

  const _shipment = useMemo(
    () => rateMutation.data?.shipmentGetRates || shipment,
    [shipment, rateMutation.data?.shipmentGetRates],
  );

  const totalWeight = useMemo(
    () => sumBy(values.packages, (p) => p.weight),
    [values.packages],
  );

  useEffect(() => {
    if (rateMutation.data?.shipmentGetRates?.chosen_rate) {
      setFieldValue(
        'chosenRate',
        rateMutation.data.shipmentGetRates.chosen_rate._id,
      );
    }
  }, [setFieldValue, rateMutation.data?.shipmentGetRates?.chosen_rate]);

  useEffect(() => {
    if (values.shipDate) {
      setFieldValue('selectedShipDay', moment(values.shipDate).format('dddd'));
    }
  }, [setFieldValue, values.shipDate]);

  const handleGetRates = useCallback(async () => {
    if (
      !shipment.vendor?.prefers_dropoff &&
      values.shipDate &&
      !includes(shipment.vendor?.pickup_days, moment(values.shipDate).day())
    ) {
      return setFieldError(
        'shipDate',
        `Vendor only allows pickups on ${values.vendorPickupDays}`,
      );
    }

    // if (values.vendorPrefersDropoff && !values.chosenAccessPoint) {
    //   return setFieldError('vendorDropoff', 'Please pick an access point.');
    // }

    try {
      if (values.shipDate) {
        await getRates({
          variables: {
            id: _shipment._id,
            shipDate: values.shipDate,
            packages: map(
              values.packages,
              ({ external_id, weight, length, width, height }, idx) => ({
                external_id:
                  external_id ||
                  `${orderId}-${shipment.vendor?.external_id}-${idx + 1}`,
                weight,
                dimensions: { length, width, height },
              }),
            ),
            accessPointId: values.chosenAccessPoint,
          },
        });
        setActiveKey('2');
      }
    } catch (error) {
      if (error instanceof ApolloError) {
        console.error(error);
      }
    }
  }, [
    shipment.vendor?.prefers_dropoff,
    shipment.vendor?.pickup_days,
    shipment.vendor?.external_id,
    values.shipDate,
    values.vendorPickupDays,
    values.packages,
    values.chosenAccessPoint,
    setFieldError,
    getRates,
    _shipment._id,
    orderId,
  ]);

  return (
    <div>
      {rateMutation.error && (
        <Alert variant="danger">{rateMutation.error.message}</Alert>
      )}
      <Accordion activeKey={activeKey} onSelect={setActiveKey}>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Shipment</Accordion.Header>
          <Accordion.Body>
            <Row md={2} className="m-1 mb-3 g-3">
              {shipment.vendor?.prefers_dropoff ? (
                <Col>
                  <InputText
                    label="Vendor Prefers Dropoff?"
                    type="text"
                    readOnly
                  />
                </Col>
              ) : (
                <Col>
                  <InputText label="Vendor Pickup Days" type="text" readOnly />
                </Col>
              )}
              <Col>
                <InputText label="Requested Date" type="text" readOnly />
              </Col>
              <Col>
                <InputText label="Selected Ship Day" type="text" readOnly />
              </Col>
              <Col>
                <InputText label="Ship Date" type="date" required />
              </Col>
            </Row>
            <hr />
            <FieldArray
              name="packages"
              required
              render={(arrayHelpers: ArrayHelpers) => (
                <div>
                  {map(values.packages, (_, idx) => (
                    <Card key={idx} className="mb-3">
                      <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>Package #{idx + 1}</div>
                          {idx > 0 && (
                            <Button
                              content="Remove"
                              size="sm"
                              variant="secondary"
                              icon={faTrash}
                              className="me-2"
                              onClick={() => arrayHelpers.remove(idx)}
                            />
                          )}
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <InputText
                          label="External Id"
                          nameOveride={`packages.${idx}.external_id`}
                          type="text"
                          hidden
                        />
                        <Row className="mx-1 mb-1 g-3">
                          <Col md={12} lg={3}>
                            <InputText
                              label="Weight"
                              type="number"
                              nameOveride={`packages.${idx}.weight`}
                              required
                            />
                          </Col>
                          <Col md={4} lg={3}>
                            <InputText
                              label="Length"
                              type="number"
                              nameOveride={`packages.${idx}.length`}
                              required
                            />
                          </Col>
                          <Col md={4} lg={3}>
                            <InputText
                              label="Width"
                              type="number"
                              nameOveride={`packages.${idx}.width`}
                              required
                            />
                          </Col>
                          <Col md={4} lg={3}>
                            <InputText
                              label="Height"
                              type="number"
                              nameOveride={`packages.${idx}.height`}
                              required
                            />
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  ))}
                  <div className="d-flex justify-content-end">
                    <Button
                      content="Add Package"
                      size="sm"
                      variant="secondary"
                      icon={faPlus}
                      className="me-2"
                      onClick={() => arrayHelpers.push({})}
                    />
                    <Button
                      content="Get Rates"
                      size="sm"
                      variant="primary"
                      icon={faTruckFast}
                      isLoading={rateMutation.loading}
                      disabled={!isValid}
                      onClick={handleGetRates}
                    />
                  </div>
                </div>
              )}
            />
            {/* <Button
              content="Choose Drop-Off"
              size="sm"
              variant="primary"
              icon={faDolly}
              isLoading={rateMutation.loading}
              disabled={!!errors.shipDate}
              onClick={() => setActiveKey('')}
            /> */}
            <InputText label="Chosen Rate" type="text" hidden />
          </Accordion.Body>
        </Accordion.Item>
        {/* {shipment.vendor && values.vendorDropoff && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>Access Points</Accordion.Header>
            <Accordion.Body>
              <AccessPoint
                setFieldValue={setFieldValue}
                values={values}
                vendor={shipment.vendor}
              />
              <div className="text-end">
                <Button
                  content="Get Rates"
                  size="sm"
                  variant="primary"
                  icon={faTruckFast}
                  isLoading={rateMutation.loading}
                  disabled={!values.chosenAccessPoint}
                  onClick={handleGetRates}
                />
              </div>
            </Accordion.Body>
          </Accordion.Item>
        )} */}
        <Accordion.Item eventKey="2">
          <Accordion.Header>Rates</Accordion.Header>
          <Accordion.Body>
            <Card className="mb-3" body>
              <Row className="align-items-center">
                {map(
                  [
                    { label: 'Ship Date', value: values.shipDate },
                    {
                      label: 'Total Packages',
                      value: values.packages?.length,
                    },
                    {
                      label: 'Total Weight',
                      value: (
                        <span>
                          {totalWeight}&nbsp;
                          <span className="small text-muted">
                            {totalWeight > 1 ? '(lbs.)' : '(lb.)'}
                          </span>
                        </span>
                      ),
                    },
                  ],
                  (v, i) => (
                    <Col key={i} className="text-center">
                      <div className="small">{v.label}:</div>
                      <div className="fw-bold">{v.value}</div>
                    </Col>
                  ),
                )}
                <Col className="text-end">
                  <Button
                    content="Refresh Rates"
                    size="sm"
                    variant="primary"
                    icon={faTruckFast}
                    isLoading={rateMutation.loading}
                    disabled={
                      !isValid || isArrayNullOrEmpty(shipment.all_rates)
                    }
                    onClick={handleGetRates}
                  />
                </Col>
              </Row>
            </Card>
            <div className="text-end mt-2 mb-3"></div>
            <Table<ShipmentRate>
              onSelect={(chosenRate) =>
                setFieldValue('chosenRate', chosenRate._id)
              }
              rows={sortBy(_shipment.all_rates, (r) => r.total_amount)}
              columns={[
                {
                  label: '',
                  field: '_id',
                  formatter(row) {
                    return (
                      <FontAwesomeIcon
                        icon={faCircleCheck}
                        className={
                          row._id === values.chosenRate
                            ? 'text-primary'
                            : 'text-dark text-opacity-25'
                        }
                      />
                    );
                  },
                },
                {
                  label: 'Account',
                  formatter(row) {
                    return (
                      <span className="small">
                        {row.carrier?.nickname === 'henry@farmshare.co'
                          ? `ShipEngine ${row.carrier?.friendly_name}`
                          : row.carrier?.nickname}
                      </span>
                    );
                  },
                },
                {
                  label: 'Service',
                  field: 'service_type',
                  formatter(row) {
                    return <span className="small">{row.service_type}</span>;
                  },
                },
                {
                  label: 'Type',
                  field: 'package_type',
                  formatter(row) {
                    return (
                      <span className="small">
                        {startCase(row.package_type)}
                      </span>
                    );
                  },
                },
                {
                  label: 'Days',
                  field: 'delivery_days',
                },
                {
                  // label: <div className="text-end">Price</div>,
                  label: 'Price',
                  field: 'total_amount',
                  formatter(row) {
                    return (
                      <div className="text-end">
                        {formatToCurrency(row.total_amount)}
                      </div>
                    );
                  },
                },
              ]}
            />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}
