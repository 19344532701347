import type { Theme } from '@farmshare/ui-components';
import { atom } from 'recoil';

import type { User } from 'lib/graphql';

// THEME
const themeState = atom<Theme>({ key: 'theme', default: 'dark' });

// USER
const userState = atom<User>({ key: 'user', default: undefined });

export { themeState, userState, type Theme };
