import Logo from '../assets/images/farmshare.svg';

export default function Home() {
  return (
    <div className="position-absolute top-0 start-0 d-flex flex-column justify-content-center w-100 vh-100 align-content-center align-items-center text-primary z-n1">
      <div className="align-items-center d-flex flex-column mx-auto w-50">
        <img src={Logo} alt="Farmshare.co Logo" className="w-75" />
        <div className="fs-1">Middleware</div>
      </div>
    </div>
  );
}
