import { InputText } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import type { FormikProps } from 'formik';
import { round } from 'lodash';
import { useMemo } from 'react';
import { Stack } from 'react-bootstrap';

export interface EditVendorPayoutModalForm {
  subTotal: number;
  shipping: number;
  fee: number;
}

interface EditVendorPayoutModalProps {
  fP: FormikProps<EditVendorPayoutModalForm>;
}

export function EditVendorPayoutModal(props: EditVendorPayoutModalProps) {
  const total = useMemo(
    () =>
      round(
        props.fP.values.subTotal -
          props.fP.values.shipping -
          props.fP.values.fee,
        2,
      ),
    [props.fP.values.fee, props.fP.values.shipping, props.fP.values.subTotal],
  );

  return (
    <Stack gap={3}>
      <InputText type="number" label="SubTotal" min={0} required />
      <InputText type="number" label="Shipping" min={0} required />
      <InputText type="number" label="Fee" min={0} step={0.01} required />
      <Stack direction="horizontal" gap={3} className="justify-content-center">
        <div className="fs-5">Total</div>
        <div className="fs-4 fw-bold">{formatToCurrency(total)}</div>
      </Stack>
    </Stack>
  );
}
