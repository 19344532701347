import { FocusEventHandler } from 'react';

export interface InputComponent {
  className?: string;
  disabled?: boolean;
  floatingLabel?: boolean;
  hidden?: boolean;
  hint?: string;
  label?: string;
  nameOveride?: string;
  readOnly?: boolean;
  required?: boolean;
  showLabel?: boolean;
  onBlur?: FocusEventHandler<HTMLInputElement>;
}

export { InputCheck } from './input-check';
export { InputQuantity } from './input-quantity';
export { InputSelect } from './input-select';
export { InputText } from './input-text';
export { InputTextArea } from './input-textarea';
export { InputTypeahead } from './input-typeahead';
