import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Logo from '../../assets/images/logo-roca-white.svg';

export function Loading() {
  return (
    <div className="position-absolute top-0 start-0 d-flex w-100 vh-100 align-content-center align-items-center z-n1 bg-primary">
      <div className="align-items-center d-flex flex-column mx-auto w-50">
        <FontAwesomeIcon
          icon={faSpinner}
          size="10x"
          className="text-white mb-3"
          spin
        />
        <img src={Logo} alt="Farmshare.co Logo" className="w-75 text-white" />
      </div>
    </div>
  );
}
