import { InputSelect, InputText } from '@farmshare/ui-components';
import { FieldArray, type FormikProps } from 'formik';
import { map, uniqBy } from 'lodash';
import { Stack } from 'react-bootstrap';

import { useCarrierManyQuery, type Shipment } from 'lib/graphql';

enum EDeliveryType {
  HandDelivery = 'Hand Delivery',
  Carrier = 'Carrier',
}

export interface UpdateShipmentModalForm {
  deliveryType?: EDeliveryType;
  carrierCode?: string;
  trackingNumbers?: string[];
}

interface UpdateShipmentModalProps {
  shipment: Shipment;
  fP: FormikProps<UpdateShipmentModalForm>;
}

export function UpdateShipmentModal(props: UpdateShipmentModalProps) {
  const { data } = useCarrierManyQuery();

  return (
    <div>
      <Stack gap={3}>
        <InputSelect
          label="Delivery Type"
          options={map(EDeliveryType, (label, value) => ({ label, value }))}
          required
        />
        {props.fP.values.deliveryType === EDeliveryType.Carrier && (
          <>
            <InputSelect
              label="Carrier"
              nameOveride="carrierCode"
              options={map(uniqBy(data?.carrierMany, 'carrier_code'), (c) => ({
                label: c.friendly_name,
                value: c.carrier_code,
              }))}
              required
            />
            <FieldArray
              name="trackingNumbers"
              required
              render={() =>
                map(props.shipment.packages, (p, i) => (
                  <InputText
                    key={p._id}
                    type="text"
                    label={`Tracking Number - ${p.external_id}`}
                    nameOveride={`trackingNumbers.${i}`}
                    required
                  />
                ))
              }
            />
          </>
        )}
      </Stack>
    </div>
  );
}
