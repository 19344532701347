import { faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ExternalLinkProps {
  href: string;
  text?: string;
  icon?: boolean;
}

export function ExternalLink({ href, text, icon = true }: ExternalLinkProps) {
  return (
    <a
      href={href}
      className="text-decoration-none"
      target="_blank"
      rel="noreferrer"
    >
      {icon && <FontAwesomeIcon icon={faExternalLink} className="me-2" />}
      {text && <span>{text}</span>}
    </a>
  );
}
