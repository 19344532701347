import { OperationVariables, QueryResult } from '@apollo/client';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  type DeepKeys,
  type Row,
  type SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
  getExpandedRowModel,
} from '@tanstack/react-table';
import { concat, join, map } from 'lodash';
import {
  type ReactNode,
  useCallback,
  useMemo,
  useState,
  Fragment,
} from 'react';
import {
  Button,
  Fade,
  Table as RBTable,
  type TableProps as RBTableProps,
} from 'react-bootstrap';

type TBreakpoints = 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
type TField<TData> = DeepKeys<TData>;

export interface ColumnProps<TData, TQuery, TVars extends OperationVariables> {
  label?: string;
  field?: TField<TData>;
  formatter?: (
    row: TData,
    rowNumber: number,
    operation?: QueryResult<TQuery, TVars>,
  ) => ReactNode;
  footer?: {
    aggregation: (collection: TData[], field: TField<TData>) => number;
    formatter?: (val: number) => ReactNode;
  };
  className?: string | string[];
  minimumBreakpoint?: TBreakpoints;
  minSize?: number;
  maxSize?: number;
}

export interface TableProps<TData, TQuery, TVars extends OperationVariables> {
  columns: ColumnProps<TData, TQuery, TVars>[];
  rows: TData[];
  onSelect?: (row: TData) => void;
  props?: Partial<RBTableProps>;
  rowAlignment?: 'top' | 'middle' | 'bottom';
  renderSubComponent?: (
    row: Row<TData>,
    operation?: QueryResult<TQuery, TVars>,
  ) => ReactNode;
}

export function Table<
  TData,
  TQuery = {},
  TVars extends OperationVariables = {},
>({
  columns,
  renderSubComponent,
  rows,
  onSelect,
  props,
  rowAlignment = 'middle',
}: TableProps<TData, TQuery, TVars>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const breakpointClass = useCallback(
    (breakpoint?: TBreakpoints) => ['d-none', `d-${breakpoint}-table-cell`],
    [],
  );

  const _columns = useMemo(() => {
    const cols = map(
      columns,
      (
        {
          label,
          field,
          formatter,
          minSize,
          maxSize,
          footer,
          className,
          minimumBreakpoint,
        },
        id,
      ) => {
        const columnHelper = createColumnHelper<TData>();

        const header = () => (
          <div
            className={join(
              concat(
                minimumBreakpoint ? breakpointClass(minimumBreakpoint) : [],
                className,
              ),
              ' ',
            )}
          >
            {label}
          </div>
        );

        if (field) {
          return columnHelper.accessor(field, {
            header,
            minSize,
            maxSize,
            id: id.toString(),
            cell: (props) => (
              <div
                className={join(
                  concat(
                    minimumBreakpoint ? breakpointClass(minimumBreakpoint) : [],
                    className,
                  ),
                  ' ',
                )}
              >
                {formatter
                  ? formatter(props.row.original, props.row.index + 1)
                  : props.renderValue()}
              </div>
            ),
            footer: () => (
              <div
                className={join(
                  concat(
                    minimumBreakpoint ? breakpointClass(minimumBreakpoint) : [],
                    className,
                  ),
                  ' ',
                )}
              >
                {footer?.formatter
                  ? footer.formatter(footer.aggregation(rows, field))
                  : footer?.aggregation(rows, field)}
              </div>
            ),
          });
        }

        return columnHelper.display({
          minSize,
          maxSize,
          header,
          id: id.toString(),
          cell: (props) => {
            const val = formatter
              ? formatter(props.row.original, props.row.index + 1)
              : props.getValue();

            return (
              <div
                className={join(
                  concat(
                    minimumBreakpoint ? breakpointClass(minimumBreakpoint) : [],
                    className,
                  ),
                  ' ',
                )}
              >
                {val as ReactNode}
              </div>
            );
          },
        });
      },
    );

    if (renderSubComponent) {
      return concat(
        [
          {
            id: 'expander',
            header: () => null,
            cell: ({ row }) => {
              const isExpanded = row.getIsExpanded();
              return row.getCanExpand() ? (
                <Button
                  {...{
                    variant: 'ghost',
                    onClick: row.getToggleExpandedHandler(),
                    style: { cursor: 'pointer', width: '32px' },
                  }}
                >
                  <FontAwesomeIcon
                    icon={isExpanded ? faCaretDown : faCaretRight}
                    className={isExpanded ? 'text-primary' : ''}
                  />
                </Button>
              ) : null;
            },
          },
        ],
        cols,
      );
    }

    return cols;
  }, [breakpointClass, columns, renderSubComponent, rows]);

  const table = useReactTable<TData>({
    data: rows,
    columns: _columns,
    state: {
      sorting,
    },
    enableColumnFilters: false,
    getRowCanExpand: () => !!renderSubComponent,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // debugTable: true,
  });

  return (
    <RBTable size="sm" hover borderless {...props}>
      <thead>
        {map(table.getHeaderGroups(), (headerGroup) => (
          <tr key={headerGroup.id}>
            {map(headerGroup.headers, (header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <Fragment key={row.id}>
            <tr
              className={`align-${rowAlignment} ${
                row.getIsExpanded() ? 'table-active' : ''
              }`}
              onClick={() => onSelect && onSelect(row.original)}
              style={onSelect ? { cursor: 'pointer' } : {}}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
            {renderSubComponent && (
              <Fade in={row.getIsExpanded()}>
                <tr className="table-active">
                  <td colSpan={row.getVisibleCells().length}>
                    {renderSubComponent(row)}
                  </td>
                </tr>
              </Fade>
            )}
          </Fragment>
        ))}
      </tbody>
      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((footer) => (
              <th key={footer.id}>
                {footer.isPlaceholder
                  ? null
                  : flexRender(
                      footer.column.columnDef.footer,
                      footer.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </RBTable>
  );
}
