import { QueryResult } from '@apollo/client';
import { Button, useModal, useToastr } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import {
  faDollarSign,
  faEdit,
  faEye,
  // faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { environment } from 'environments/environment';
import { ReactNode, useMemo } from 'react';
import { Stack } from 'react-bootstrap';

import {
  EnumVendorPayoutStatus,
  VendorPayout,
  VendorPayoutPaginationQuery,
  VendorPayoutPaginationQueryVariables,
  useVendorPayoutEditMutation,
  // useVendorPayoutRemoveByIdMutation,
  useVendorPayoutSendMutation,
} from 'lib/graphql';

import {
  EditVendorPayoutModal,
  EditVendorPayoutModalForm,
} from 'pages/payouts/_views/edit-payout-modal';

import { VendorPayoutDetails } from 'views/vendor-payouts/details';

interface VendorPayoutActionButtonsProps {
  vendorPayout: VendorPayout;
  op?: QueryResult<
    VendorPayoutPaginationQuery,
    VendorPayoutPaginationQueryVariables
  >;
}

export function VendorPayoutActionButtons({
  vendorPayout,
  op,
}: VendorPayoutActionButtonsProps) {
  const { ask, save } = useModal();
  const { push } = useToastr();

  const [editVendorPayout, editVendorPayoutOp] = useVendorPayoutEditMutation();
  const [sendVendorPayout, sendVendorPayoutOp] = useVendorPayoutSendMutation();
  // const [deletePayout, deletePayoutOp] = useVendorPayoutRemoveByIdMutation();

  const buttons = useMemo(() => {
    const _buttons: ReactNode[] = [];

    switch (vendorPayout.status) {
      case EnumVendorPayoutStatus.Unpaid:
        _buttons.push(
          <Button
            block
            icon={faDollarSign}
            content="Pay"
            size="sm"
            onClick={() =>
              ask({
                type: 'ask',
                icon: faDollarSign,
                title: 'Send Payout to Vendor?',
                size: 'lg',
                isLoading: sendVendorPayoutOp.loading,
                yesText: `Pay ${formatToCurrency(vendorPayout.total)}`,
                yesDisabled:
                  !vendorPayout.shipment?.vendor?.account?.payouts_enabled,
                error:
                  sendVendorPayoutOp.error?.message ||
                  (!vendorPayout.shipment?.vendor?.account?.payouts_enabled
                    ? `No Stripe account found for ${vendorPayout.shipment?.vendor?.shop_name}. Vendor cannot accept payments.`
                    : undefined),
                onConfirm: async () => {
                  await sendVendorPayout({
                    variables: { payoutId: vendorPayout._id },
                  });
                  push({
                    title: 'Success',
                    body: 'Payout successfully sent.',
                    bg: 'primary',
                    delay: 4000,
                  });
                  op?.refetch();
                },
                body: <VendorPayoutDetails vendorPayout={vendorPayout} />,
              })
            }
          />,
        );
        _buttons.push(
          <Button
            block
            icon={faEdit}
            content="Edit"
            size="sm"
            isLoading={editVendorPayoutOp.loading}
            onClick={() => {
              console.log(vendorPayout);
              save<EditVendorPayoutModalForm>({
                type: 'save',
                title: 'Edit Payout',
                icon: faEdit,
                isLoading: editVendorPayoutOp.loading,
                initialValues: {
                  subTotal: vendorPayout.subTotal,
                  shipping: vendorPayout.shipping,
                  fee: vendorPayout.shipment?.fee || 0,
                },
                body: (fP) => <EditVendorPayoutModal fP={fP} />,
                onSubmit: async ({ subTotal, shipping, fee }) => {
                  await editVendorPayout({
                    variables: {
                      payoutId: vendorPayout._id,
                      subTotal,
                      shipping,
                      fee,
                    },
                  });
                  push({
                    title: 'Success',
                    body: 'Payout successfully edited.',
                    bg: 'primary',
                    delay: 4000,
                  });
                  op?.refetch();
                },
              });
            }}
          />,
        );
        // _buttons.push(
        //   <Button
        //     block
        //     icon={faTrash}
        //     content="Delete"
        //     isLoading={deletePayoutOp.loading}
        //     size="sm"
        //     onClick={() =>
        //       ask({
        //         type: 'ask',
        //         icon: faTrash,
        //         title: 'Delete Payout?',
        //         isLoading: deletePayoutOp.loading,
        //         variant: 'danger',
        //         size: 'lg',
        //         yesIcon: faTrash,
        //         yesText: 'Delete',
        //         onConfirm: async () => {
        //           await deletePayout({
        //             variables: { payoutId: vendorPayout._id },
        //           });
        //         },
        //         body: <VendorPayoutDetails vendorPayout={vendorPayout} />,
        //       })
        //     }
        //   />,
        // );
        break;

      case EnumVendorPayoutStatus.Paid:
        _buttons.push(
          <Button
            block
            icon={faDollarSign}
            content="Reverse"
            size="sm"
            variant="warning"
            onClick={() =>
              ask({
                type: 'ask',
                title: 'Send Refund to Vendor?',
                size: 'lg',
                isLoading: sendVendorPayoutOp.loading,
                yesDisabled:
                  !vendorPayout.shipment?.vendor?.account?.payouts_enabled,
                error:
                  sendVendorPayoutOp.error?.message ||
                  (!vendorPayout.shipment?.vendor?.account?.payouts_enabled
                    ? 'Vendor cannot accept payments.'
                    : undefined),
                onConfirm: async () => {
                  await sendVendorPayout({
                    variables: { payoutId: vendorPayout._id },
                  });
                },
                body: <VendorPayoutDetails vendorPayout={vendorPayout} />,
              })
            }
          />,
        );
        break;

      default:
        _buttons.push(
          <Button
            block
            icon={faEye}
            content="View"
            variant="warning"
            size="sm"
            href={`https://dashboard.stripe.com${
              environment.production ? '' : '/test'
            }/payments/${vendorPayout.shipment?.order?.transaction?.intent_id}`}
            target="_blank"
          />,
        );
        break;
    }

    return _buttons;
  }, [
    ask,
    editVendorPayout,
    editVendorPayoutOp.loading,
    op,
    push,
    save,
    sendVendorPayout,
    sendVendorPayoutOp.error?.message,
    sendVendorPayoutOp.loading,
    vendorPayout,
  ]);

  return (
    <Stack direction="horizontal" gap={2}>
      {buttons}
    </Stack>
  );
}
