import {
  DataDetailList,
  Error as ErrorComponent,
  Loading,
  PageTitle,
} from '@farmshare/ui-components';
import { formatToCurrency, formatToShortDate } from '@farmshare/utils';
// import { faStore } from '@fortawesome/free-solid-svg-icons';
// import { environment } from 'environments/environment';
import { isNumber, toNumber, map, first, startCase } from 'lodash';
import { useEffect } from 'react';
import { Accordion, Card, Col, Container, Row } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';

import { useOrderOneLazyQuery } from 'lib/graphql';
import { addressFieldsArray } from 'lib/helpers';

import ShipmentDetails from 'views/shipment-details';

export default function OrdersDetails() {
  const { hash } = useLocation();
  const { orderId } = useParams();

  const [load, { data, loading, error, called, refetch }] =
    useOrderOneLazyQuery();

  useEffect(() => {
    if (orderId) {
      const external_id = toNumber(orderId);

      if (isNumber(external_id)) {
        load({ variables: { external_id } });
      }
    }
  }, [load, orderId]);

  if (error || (called && data && !data?.orderOne)) {
    console.error(error);
    return (
      <ErrorComponent
        error={error ? error : new Error(`Order #${orderId} not found.`)}
      />
    );
  }

  return loading ? (
    <Loading />
  ) : (
    <div>
      {data?.orderOne && (
        <>
          <PageTitle
            title={
              <>
                Order&nbsp;
                <span className="small fw-light text-muted">#{orderId}</span>
              </>
            }
            innerBreadcrumbs={[{ to: '/Orders', text: 'Orders' }]}
            breadCrumbLabel={`Order #${orderId}`}
          />
          <Container>
            <Card className="mb-3">
              <Card.Header className="bg-primary text-white">
                <h6 className="mt-2">Order Information</h6>
              </Card.Header>
              <Card.Body>
                {/* <div className="text-end my-3 mx-2">
                  <Button
                    content="View in Woo"
                    icon={faStore}
                    href={`${environment.wooCommerceUrl}/wp-admin/post.php?post=${orderId}&action=edit`}
                    size="sm"
                    target="_blank"
                  />
                </div> */}
                <Container>
                  <Row>
                    <Col md={5}>
                      <DataDetailList
                        heading="Shipping"
                        rows={addressFieldsArray(data.orderOne.shipping)}
                      />
                    </Col>
                    <Col md={3}>
                      <DataDetailList
                        heading="Transaction"
                        rows={[
                          {
                            label: 'Method',
                            value: data.orderOne.transaction?.payment_method,
                          },
                          {
                            label: 'Total',
                            value: formatToCurrency(data.orderOne.total),
                          },
                          {
                            label: 'Fee',
                            value: formatToCurrency(
                              data.orderOne.transaction?.fee,
                            ),
                          },
                          {
                            label: 'Net',
                            value: formatToCurrency(
                              data.orderOne.transaction?.net,
                            ),
                          },
                          {
                            label: 'Shipping Paid',
                            value: formatToCurrency(
                              data.orderOne.shipping_paid,
                            ),
                          },
                        ]}
                      />
                    </Col>
                    <Col md={4}>
                      <DataDetailList
                        heading="Details"
                        rows={[
                          {
                            label: 'Status',
                            value: startCase(data.orderOne.status),
                          },
                          {
                            label: 'Date Created',
                            value: formatToShortDate(
                              data.orderOne.date_created,
                            ),
                          },
                          {
                            label: 'Date Completed',
                            value: formatToShortDate(
                              data.orderOne.date_completed,
                            ),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Container>
              </Card.Body>
            </Card>
            <Accordion
              defaultActiveKey={
                hash !== '' ? hash : `#${first(data.orderOne.shipments)?._id}`
              }
              // onSelect={(_id) => {
              // @todo: replace hash in url onSelect
              // }}
            >
              {map(data.orderOne.shipments, (shipment, idx, arr) => (
                <ShipmentDetails
                  key={shipment._id}
                  orderId={data.orderOne?.external_id || 0}
                  shipment={shipment}
                  shipmentNumber={idx + 1}
                  totalNumberOfShipments={arr.length}
                  refetch={refetch}
                />
              ))}
            </Accordion>
          </Container>
        </>
      )}
    </div>
  );
}
