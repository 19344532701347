import { PaginationTablePage } from '@farmshare/ui-components';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { keyBy, mapValues } from 'lodash';
import { Link } from 'react-router-dom';

import {
  SortFindManyVendorInput,
  type Vendor,
  type VendorPagination,
  type VendorPaginationQuery,
  type VendorPaginationQueryVariables,
  useVendorPaginationLazyQuery,
} from 'lib/graphql';

export default function Vendors() {
  return (
    <PaginationTablePage<
      Vendor,
      VendorPagination,
      VendorPaginationQuery,
      VendorPaginationQueryVariables,
      SortFindManyVendorInput
    >
      buildFilterQuery={(allFilters, defaultSort, page, perPage) => ({
        filter: mapValues(
          keyBy(allFilters, (k) => k.value),
          (v) => v.isActive,
        ),
        sort: defaultSort,
        page,
        perPage,
      })}
      dataAccessor={(a) => a.vendorPagination as VendorPagination}
      paginationQuery={useVendorPaginationLazyQuery()}
      filters={[{ label: 'Enabled', value: 'enabled' }]}
      defaultFilters={['enabled']}
      defaultSort={SortFindManyVendorInput.VendorNameAsc}
      title="Vendors"
      columns={[
        {
          label: 'Name',
          formatter: (row) => (
            <Link to={`/vendors/${row.external_id}`}>{row.shop_name}</Link>
          ),
        },
        { label: 'Email', field: 'address.email', minimumBreakpoint: 'xl' },
        {
          label: 'Enabled?',
          formatter: (row) => (
            <FontAwesomeIcon
              icon={row.enabled ? faCheck : faXmark}
              className={row.enabled ? 'text-primary' : ''}
            />
          ),
        },
        {
          label: 'Products',
          formatter(row) {
            return row.products?.length;
          },
        },
        {
          label: 'Orders',
          formatter(row) {
            return row.shipments?.length;
          },
        },
        {
          label: 'Stripe Enabled?',
          formatter: (row) =>
            row.account?.payouts_enabled ? (
              <FontAwesomeIcon icon={faCheck} className="text-primary" />
            ) : (
              ''
            ),
        },
        {
          label: 'Prefers Drop-off?',
          formatter: (row) =>
            row.prefers_dropoff ? (
              <FontAwesomeIcon icon={faCheck} className="text-primary" />
            ) : (
              ''
            ),
        },
      ]}
    />
  );
}
