import {
  Error as ErrorComponent,
  Loading,
  PageTitle,
} from '@farmshare/ui-components';
import { isNumber, sortBy, toNumber } from 'lodash';
import { useEffect } from 'react';
import { Accordion, Card, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { useVendorOneLazyQuery } from 'lib/graphql';

import VendorDetailsInfo from 'views/vendor-details';
import VendorDetailsProducts from 'views/vendor-details/vendor-details-products';
import VendorDetailsShipments from 'views/vendor-details/vendor-details-shipments';

export default function VendorDetails() {
  const { vendorId } = useParams();

  const [load, { data, loading, error, called }] = useVendorOneLazyQuery();

  useEffect(() => {
    if (vendorId) {
      const external_id = toNumber(vendorId);

      if (isNumber(external_id)) {
        load({ variables: { external_id } });
      }
    }
  }, [load, vendorId]);

  if (error || (called && data && !data?.vendorOne)) {
    console.error(error);
    return (
      <ErrorComponent
        error={error ? error : new Error(`Order #${vendorId} not found.`)}
      />
    );
  }

  return loading ? (
    <Loading />
  ) : (
    <div>
      {data?.vendorOne && (
        <>
          <PageTitle
            title={data.vendorOne.shop_name}
            innerBreadcrumbs={[{ to: '/vendors', text: 'Vendors' }]}
          />
          <Container>
            <Card className="mb-3">
              <Card.Header className="bg-primary text-white">
                <h6 className="mt-2">Vendor Information</h6>
              </Card.Header>
              <Card.Body>
                <VendorDetailsInfo vendor={data.vendorOne} />
              </Card.Body>
            </Card>
            <Accordion defaultActiveKey="products">
              <Accordion.Item eventKey="products">
                <Accordion.Header>Products</Accordion.Header>
                <Accordion.Body>
                  <VendorDetailsProducts products={data.vendorOne.products} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="orders">
                <Accordion.Header>Shipments</Accordion.Header>
                <Accordion.Body>
                  <VendorDetailsShipments
                    shipments={sortBy(data.vendorOne.shipments)}
                  />
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </>
      )}
    </div>
  );
}
