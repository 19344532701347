import { DataDetailList } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import {
  faCheckCircle,
  faXmarkCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { join, map, toNumber } from 'lodash';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';

import { Vendor } from 'lib/graphql';
import { addressFieldsArray } from 'lib/helpers';

interface VendorDetailsProps {
  vendor: Vendor;
}

export default function VendorDetailsInfo({ vendor }: VendorDetailsProps) {
  return (
    <Container>
      <Row md={2}>
        <Col>
          <DataDetailList
            heading="Address"
            rows={addressFieldsArray(vendor.address)}
          />
        </Col>
        <Col>
          <DataDetailList
            heading="Logistics"
            rows={[
              {
                label: 'Enabled?',
                value: vendor.enabled ? (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    className="text-primary"
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faXmarkCircle}
                    className="text-danger"
                  />
                ),
              },
              {
                label: 'Pickup Days',
                value: join(
                  map(vendor.pickup_days, (pd) =>
                    moment().day(toNumber(pd)).format('ddd'),
                  ),
                  ', ',
                ),
              },
              {
                label: 'Free Shipping Threshold',
                value: formatToCurrency(
                  vendor.free_shipping_amount || undefined,
                ),
              },
              {
                label: 'Prefers Dropoff?',
                value: vendor.prefers_dropoff ? 'Yes' : 'No',
              },
              {
                label: 'Packaging',
                value: (
                  <ul>
                    {map(vendor.packaging, (p, idx) => (
                      <li key={idx}>
                        {p?.length}x{p?.width}x{p?.height}
                      </li>
                    ))}
                  </ul>
                ),
              },
            ]}
          />
        </Col>
      </Row>
    </Container>
  );
}
