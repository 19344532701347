import { assign, join, map, split } from 'lodash';
import { ReactNode, useEffect } from 'react';
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Button, { ButtonProps } from '../button/button';

interface PageTitleProps {
  title: ReactNode;
  breadCrumbLabel?: string;
  actionButtons?: ButtonProps[];
  innerBreadcrumbs?: { text: string; to: string }[];
  ellipsis?: ReactNode;
  showHr?: boolean;
}

export function PageTitle({
  title,
  actionButtons,
  innerBreadcrumbs,
  breadCrumbLabel,
  ellipsis,
  showHr = true,
}: PageTitleProps) {
  useEffect(() => {
    if (title) {
      const delimiter = ' | ';
      const [base] = split(window.document.title, delimiter);
      window.document.title = join([base, breadCrumbLabel || title], delimiter);
    }
  }, [breadCrumbLabel, title]);

  return (
    <Container>
      <div className="d-flex align-items-center gap-2">
        <h1 className="flex-grow-1">{title}</h1>
        {map(actionButtons, (actionButton, idx) => (
          <div key={idx}>
            <Button {...assign({}, actionButton)} />
          </div>
        ))}
      </div>
      <div className="d-flex align-items-center gap-2">
        <Breadcrumb className="flex-grow-1" listProps={{ className: 'mb-0' }}>
          <Breadcrumb.Item linkAs={Link} linkProps={{ to: '/' }}>
            Home
          </Breadcrumb.Item>
          {map(innerBreadcrumbs, ({ text, to }, idx) => (
            <Breadcrumb.Item key={idx} linkAs={Link} linkProps={{ to }}>
              {text}
            </Breadcrumb.Item>
          ))}
          <Breadcrumb.Item active>{breadCrumbLabel || title}</Breadcrumb.Item>
        </Breadcrumb>
        {ellipsis}
      </div>
      {showHr ? <hr /> : <div className="mb-3" />}
    </Container>
  );
}
