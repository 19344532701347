import { Loading, Table } from '@farmshare/ui-components';
import { formatToCurrency, formatToShortDate } from '@farmshare/utils';
import { startCase } from 'lodash';
import { useEffect } from 'react';

import { Shipment, useVendorPayoutManyLazyQuery } from 'lib/graphql';

interface ShipmentPayoutProps {
  shipment: Shipment;
}

export function ShipmentPayout({ shipment }: ShipmentPayoutProps) {
  const [loadData, { data }] = useVendorPayoutManyLazyQuery();

  useEffect(() => {
    if (shipment) {
      loadData({ variables: { filter: { shipment: shipment._id } } });
    }
  }, [loadData, shipment]);

  if (!data?.vendorPayoutMany) {
    return <Loading />;
  }

  return (
    <div>
      <Table
        rows={data.vendorPayoutMany}
        columns={[
          {
            label: 'Date Created',
            field: 'date_created',
            formatter: (row) => formatToShortDate(row.date_created),
          },
          {
            label: 'Payout Status',
            field: 'status',
            formatter: (row) => startCase(row.status),
          },
          {
            label: 'SubTotal',
            field: 'shipment.total',
            formatter: (row) => formatToCurrency(row.shipment?.total),
          },
          {
            label: 'Fee',
            field: 'shipment.fee',
            formatter: (row) => formatToCurrency(row.shipment?.fee),
          },
          {
            label: 'Net',
            field: 'subTotal',
            formatter: (row) => formatToCurrency(row.subTotal),
          },
          {
            label: 'Shipping',
            field: 'shipping',
            formatter: (row) => formatToCurrency(row.shipping),
          },
          {
            label: 'Total',
            field: 'total',
            formatter: (row) => formatToCurrency(row.total),
          },
        ]}
      />
    </div>
  );
}
