import {
  Button,
  Form,
  InputCheck,
  InputSelect,
  InputText,
  PageTitle,
  Table,
} from '@farmshare/ui-components';
import { formatToCurrency, isArrayNullOrEmpty } from '@farmshare/utils';
import {
  faCheckSquare,
  faSpinner,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { map, sortBy, startCase } from 'lodash';
import moment from 'moment';
import { Alert, Card, Col, Container, Row } from 'react-bootstrap';

import {
  ShipmentRate,
  useCarrierManyQuery,
  useShipmentRatesEstimateMutation,
  useStateManyQuery,
} from 'lib/graphql';

import { ShipmentEstimateLocation } from './_views/estimate-location';

interface ShipmentEstimatesForm {
  carrierIds: string[];
  shipDate: string;
  fromCountryCode: string;
  fromPostalCode?: string;
  fromCityLocality?: string;
  fromStateProvince?: string;
  toCountryCode: string;
  toPostalCode?: string;
  toCityLocality?: string;
  toStateProvince?: string;
  weight?: number;
  length?: number;
  width?: number;
  height?: number;
  confirmation:
    | 'none'
    | 'delivery'
    | 'signature'
    | 'adult_signature'
    | 'direct_signature'
    | 'delivery_mailed'
    | 'verbal_confirmation';
  addressResidentialIndicator: 'unknown' | 'yes' | 'no';
}

export default function ShipmentEstimates() {
  const { data: carriers, loading: carriesLoading } = useCarrierManyQuery();
  const { data: states } = useStateManyQuery();

  const [getRates, getRatesMutation] = useShipmentRatesEstimateMutation();

  return (
    <div>
      <PageTitle title="Estimate Rates" />
      <Form<ShipmentEstimatesForm>
        initialValues={{
          carrierIds: map(carriers?.carrierMany, (c) => c.external_id),
          shipDate: moment().add(6, 'day').startOf('week').format('yyyy-MM-DD'),
          fromCountryCode: 'US',
          toCountryCode: 'US',
          confirmation: 'none',
          addressResidentialIndicator: 'unknown',
          length: 12,
          height: 12,
          width: 12,
          weight: 0,
        }}
        validate={(values) => {
          const errors: Partial<Record<keyof ShipmentEstimatesForm, string>> =
            {};

          if (isArrayNullOrEmpty(values.carrierIds)) {
            errors.carrierIds = 'Please select at least one carrier.';
          }

          return errors;
        }}
        onSubmit={async ({
          carrierIds,
          shipDate,
          fromCityLocality,
          fromCountryCode,
          fromPostalCode,
          fromStateProvince,
          toCityLocality,
          toCountryCode,
          toPostalCode,
          toStateProvince,
          weight,
          length,
          width,
          height,
          confirmation,
          addressResidentialIndicator,
        }) => {
          if (
            fromPostalCode &&
            toPostalCode &&
            weight &&
            length &&
            width &&
            height
          ) {
            await getRates({
              variables: {
                carrierIds,
                shipDate,
                fromCityLocality,
                fromCountryCode,
                fromPostalCode,
                fromStateProvince,
                toCityLocality,
                toCountryCode,
                toPostalCode,
                toStateProvince,
                weight,
                length,
                width,
                height,
                confirmation,
                addressResidentialIndicator,
              },
            });
          }
        }}
      >
        {(formikProps) => (
          <Container>
            <Card className="mb-3">
              <Card.Header className="d-flex justify-content-between">
                <div>Carriers</div>
                <Button
                  content="Select/Unselect All"
                  size="sm"
                  icon={faCheckSquare}
                  onClick={() =>
                    formikProps.setFieldValue(
                      'carrierIds',
                      formikProps.values.carrierIds.length === 0
                        ? map(carriers?.carrierMany, (c) => c.external_id)
                        : [],
                    )
                  }
                />
              </Card.Header>
              <Card.Body>
                {carriesLoading && (
                  <Alert variant="success" className="">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      size="3x"
                      className="d-flex justify-content-center py-2 mx-auto"
                    />
                  </Alert>
                )}
                <Row xs={1} lg="auto" className="justify-content-around">
                  {map(
                    sortBy(carriers?.carrierMany, (c) => c.friendly_name),
                    (c, i) => (
                      <Col key={i}>
                        <InputCheck
                          label={
                            c.nickname === 'henry@farmshare.co'
                              ? `ShipEngine ${c.friendly_name}`
                              : c.nickname
                          }
                          nameOveride="carrierIds"
                          value={c.external_id}
                          inline
                        />
                      </Col>
                    ),
                  )}
                </Row>
              </Card.Body>
            </Card>
            <Row className="g-3">
              <Col md={6} lg={3}>
                <Card className="h-100">
                  <Card.Header>From</Card.Header>
                  <Card.Body>
                    <ShipmentEstimateLocation
                      formikProps={formikProps}
                      states={states}
                      prefix="from"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6} lg={3}>
                <Card className="h-100">
                  <Card.Header>To</Card.Header>
                  <Card.Body>
                    <ShipmentEstimateLocation
                      formikProps={formikProps}
                      states={states}
                      prefix="to"
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
                <Card className="h-100">
                  <Card.Header>Package</Card.Header>
                  <Card.Body className="">
                    <Row xs={1} md={3} className="g-3">
                      <Col>
                        <InputText
                          label="Length (in.)"
                          type="number"
                          nameOveride="length"
                          min={0}
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col>
                        <InputText
                          label="Width (in.)"
                          type="number"
                          nameOveride="width"
                          min={0}
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col>
                        <InputText
                          label="Height (in.)"
                          type="number"
                          nameOveride="height"
                          min={0}
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col md={6}>
                        <InputText
                          label="Weight (lbs.)"
                          type="number"
                          nameOveride="weight"
                          min={0}
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col md={6}>
                        <InputText
                          label="Ship Date"
                          type="date"
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <InputSelect
                          label="Address Type"
                          nameOveride="addressResidentialIndicator"
                          options={[
                            { label: 'Residential', value: 'yes' },
                            { label: 'Commercial', value: 'no' },
                            { label: 'Unknown', value: 'unknown' },
                          ]}
                          floatingLabel
                          required
                        />
                      </Col>
                      <Col xs={12} md={6}>
                        <InputSelect
                          label="Confirmation"
                          options={map(
                            [
                              'none',
                              'delivery',
                              'signature',
                              'adult_signature',
                              'direct_signature',
                              'delivery_mailed',
                              'verbal_confirmation',
                            ],
                            (c) => ({ label: startCase(c), value: c }),
                          )}
                          floatingLabel
                          required
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <div className="mt-3 text-end">
              <Button
                type="submit"
                content="Get Rates"
                icon={faTruckFast}
                isLoading={getRatesMutation.loading}
              />
            </div>
            {getRatesMutation.error && (
              <div>
                <hr />
                <Alert variant="danger">{getRatesMutation.error.message}</Alert>
              </div>
            )}
            {getRatesMutation.data && (
              <div>
                <hr />
                <Table<ShipmentRate>
                  onSelect={(chosenRate) =>
                    formikProps.setFieldValue('chosenRate', chosenRate._id)
                  }
                  rows={sortBy<ShipmentRate>(
                    getRatesMutation.data
                      .shipmentRatesEstimate as ShipmentRate[],
                    (r) => r?.total_amount,
                  )}
                  columns={[
                    {
                      label: 'Account',
                      formatter(row) {
                        return (
                          <span className="small">
                            {row.carrier?.nickname === 'henry@farmshare.co'
                              ? `ShipEngine ${row.carrier?.friendly_name}`
                              : row.carrier?.nickname}
                          </span>
                        );
                      },
                    },
                    {
                      label: 'Service',
                      field: 'service_type',
                      formatter(row) {
                        return (
                          <span className="small">{row.service_type}</span>
                        );
                      },
                    },
                    {
                      label: 'Type',
                      field: 'package_type',
                      formatter(row) {
                        return (
                          <span className="small">
                            {startCase(row.package_type)}
                          </span>
                        );
                      },
                    },
                    { label: 'Days', field: 'delivery_days' },
                    {
                      label: 'Price',
                      field: 'total_amount',
                      formatter(row) {
                        return formatToCurrency(row.total_amount);
                      },
                    },
                  ]}
                />
              </div>
            )}
          </Container>
        )}
      </Form>
    </div>
  );
}
