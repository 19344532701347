import { DataDetailList } from '@farmshare/ui-components';
import { last, map } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Card, OverlayTrigger, Popover } from 'react-bootstrap';
import { AxisOptions, AxisOptionsBase, Chart } from 'react-charts';
import { useRecoilState } from 'recoil';

import { themeState } from '../../state';

interface KPIDataPoint {
  date: string;
  count: number;
}

export interface KPIDataSeries {
  label: string;
  data: KPIDataPoint[];
}

export interface KPIViewProps {
  data: KPIDataSeries[];
  description: string;
  formatter?: (v: number) => string;
  graphType: AxisOptionsBase['elementType'];
  title: string;
}

export default function KPIView({
  data,
  description,
  formatter,
  graphType,
  title,
}: KPIViewProps) {
  const colors = ['#006f35', '#ffb659', '#275dad'];

  const [theme] = useRecoilState(themeState);

  const primaryAxis = useMemo(
    (): AxisOptions<KPIDataPoint> => ({
      getValue: (datum) => datum.date,
    }),
    [],
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<KPIDataPoint>[] => [
      {
        getValue: (datum) => datum.count,
        formatters: { tooltip: formatter },
        elementType: graphType,
        // stacked: true,
        // curve: '',
        min: 0,
      },
    ],
    [formatter, graphType],
  );

  // const popover = useCallback(
  //   () => (
  //     <Popover id="popover-basic">
  //       <Popover.Header as="h3">Popover right</Popover.Header>
  //       <Popover.Body>
  //         And here's some <strong>amazing</strong> content. It's very engaging.
  //         right?
  //       </Popover.Body>
  //     </Popover>
  //   ),
  //   [],
  // );

  return (
    <div className="h-100">
      <Card className="h-100">
        <Card.Header>{title}</Card.Header>
        <Card.Body>
          <div className="d-flex">
            <div className="flex-grow-1" style={{ height: '20vh' }}>
              <Chart
                options={{
                  data,
                  primaryAxis,
                  secondaryAxes,
                  defaultColors: colors,
                  dark: theme === 'dark',
                }}
              />
            </div>
            <div>
              {data && data.length === 1 && (
                <DataDetailList
                  rows={map(last(data)?.data, (r) => ({
                    label: (
                      // <OverlayTrigger overlay={popover()}>
                      <span>{r.date}</span>
                      // </OverlayTrigger>
                    ),
                    value: formatter ? formatter(r.count) : r.count,
                  }))}
                />
              )}
            </div>
          </div>
          <div>
            {data &&
              data.length > 1 &&
              map(data, (d, i) => (
                <span className="small p-2 pb-1">
                  <span
                    className="me-1 d-inline-block"
                    style={{
                      backgroundColor: colors[i],
                      width: '10px',
                      height: '10px',
                      borderRadius: '5px',
                    }}
                  />
                  {d.label}
                </span>
              ))}
          </div>
          <div className="fst-italic small p-2 pb-1">{description}</div>
        </Card.Body>
      </Card>
    </div>
  );
}
