import { FetchResult } from '@apollo/client';
import {
  type ButtonProps,
  useModal,
  useToastr,
  ActionButtons,
} from '@farmshare/ui-components';
import {
  faBoxesPacking,
  faPrint,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { includes, join, map } from 'lodash';
import { useMemo } from 'react';

import {
  EnumShipmentShipment_Status,
  useShipmentVoidLabelMutation,
  type Shipment,
  ShipmentVoidLabelMutation,
  useShipmentMarkAsDeliveredMutation,
  ShipmentMarkAsDeliveredMutation,
  useShipmentUpdateStatusMutation,
} from 'lib/graphql';

import {
  UpdateShipmentModal,
  UpdateShipmentModalForm,
} from './update-shipment-modal';

interface ShipmentButtonsProps {
  shipment: Shipment;
  onUpdate?: (
    updateResponse: FetchResult<ShipmentMarkAsDeliveredMutation>,
  ) => void;
  onVoid?: (voidResponse: FetchResult<ShipmentVoidLabelMutation>) => void;
  // onPickupCancel?: (
  //   cancelResponse: FetchResult<ShipmentCancelPickupMutation>,
  // ) => void;
}

export function ShipmentButtons({
  shipment,
  onUpdate,
  onVoid,
}: ShipmentButtonsProps) {
  const { ask, save } = useModal();
  const { push } = useToastr();

  const [markAsDelivered, markAsDeliveredOp] =
    useShipmentMarkAsDeliveredMutation();
  const [updateShipmentStatus, updateShipmentStatusOp] =
    useShipmentUpdateStatusMutation();
  const [voidLabel, voidLabelOp] = useShipmentVoidLabelMutation();

  const buttons = useMemo(() => {
    const _buttons: ButtonProps[] = [];

    if (
      includes(
        [
          EnumShipmentShipment_Status.New,
          EnumShipmentShipment_Status.LabelPurchased,
          EnumShipmentShipment_Status.PickupScheduled,
          EnumShipmentShipment_Status.WaitingForDropoff,
        ],
        shipment.shipment_status,
      )
    ) {
      if (shipment.shipment_status === EnumShipmentShipment_Status.New) {
        _buttons.push({
          content: 'Mark As Delivered',
          icon: faBoxesPacking,
          isLoading: markAsDeliveredOp.loading,
          onClick: async () => {
            save<UpdateShipmentModalForm>({
              type: 'save',
              title: 'Mark As Delivered',
              icon: faBoxesPacking,
              initialValues: {},
              isLoading: markAsDeliveredOp.loading,
              body: (fP) => <UpdateShipmentModal shipment={shipment} fP={fP} />,
              onSubmit: async ({ carrierCode, trackingNumbers }) => {
                await markAsDelivered({
                  variables: {
                    shipmentId: shipment._id,
                    carrierCode,
                    trackingNumbers,
                  },
                });
                push({
                  title: 'Success',
                  body: 'Shipment marked as delivered.',
                  bg: 'primary',
                  delay: 5000,
                });
                if (onUpdate) {
                  onUpdate(markAsDeliveredOp);
                }
              },
            });
          },
        });
        _buttons.push({
          content: 'Cancel Shipment',
          icon: faXmark,
          isLoading: updateShipmentStatusOp.loading,
          onClick: () =>
            ask({
              type: 'ask',
              title: 'Cancel Shipment?',
              icon: faXmark,
              isLoading: updateShipmentStatusOp.loading,
              body: (
                <p>
                  Are you sure you want to cancel the shipment from{' '}
                  <b>{shipment.vendor?.shop_name}</b>?
                </p>
              ),
              onConfirm: async () => {
                await updateShipmentStatus({
                  variables: {
                    shipmentId: shipment._id,
                    shipmentStatus: EnumShipmentShipment_Status.Cancelled,
                  },
                });
                push({
                  title: 'Success',
                  body: 'Shipment cancelled.',
                  bg: 'primary',
                  delay: 5000,
                });
                if (onUpdate) {
                  onUpdate(updateShipmentStatusOp);
                }
              },
            }),
        });
      }

      if (shipment.label_url) {
        _buttons.push({
          content: `Print Label${shipment.packages.length > 1 ? 's' : ''}`,
          icon: faPrint,
          href: shipment.label_url,
        });
      }

      if (shipment.external_label_id) {
        _buttons.push({
          content: 'Void Label',
          icon: faXmark,
          isLoading: voidLabelOp.loading,
          onClick: async () => {
            ask({
              type: 'ask',
              title: 'Void Label',
              body: `Are you sure you want to void the label${
                shipment.packages.length > 1 ? 's' : ''
              } for ${join(
                map(shipment.packages, (p) => p.tracking_number),
                ', ',
              )}?`,
              onConfirm: async () => {
                await voidLabel({
                  variables: { id: shipment._id },
                });
                push({
                  title: 'Success',
                  body: 'Label successfully voided.',
                  bg: 'primary',
                  delay: 5000,
                });
                if (onVoid) {
                  onVoid(voidLabelOp);
                }
              },
            });
          },
        });
      }
    }

    return _buttons;
  }, [
    ask,
    markAsDelivered,
    markAsDeliveredOp,
    onUpdate,
    onVoid,
    push,
    save,
    shipment,
    updateShipmentStatus,
    updateShipmentStatusOp,
    voidLabel,
    voidLabelOp,
  ]);

  return <ActionButtons buttons={buttons} />;
}
