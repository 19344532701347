import {
  faCheck,
  faLightbulb,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { isUndefined, join } from 'lodash';
import moment from 'moment';
import { ReactNode, useMemo, useState } from 'react';
import { Toast } from 'react-bootstrap';

export interface Toast {
  title: string;
  body: ReactNode;
  bg?: string;
  delay?: number;
  autohide?: boolean;
}

export default function ToastrRenderer({ toast }: { toast: Toast }) {
  const [show, setShow] = useState(true);

  const icon: FontAwesomeIconProps['icon'] = useMemo(() => {
    switch (toast.bg) {
      case 'success':
      case 'primary':
        return faCheck;
      case 'danger':
        return faXmark;
      default:
        return faLightbulb;
    }
  }, [toast.bg]);

  return (
    <Toast
      bg={toast.bg}
      onClose={() => setShow(false)}
      show={show}
      delay={!isUndefined(toast.delay) ? toast.delay : 4000}
      autohide={!isUndefined(toast.delay) || toast.autohide}
      style={{ zIndex: 9999 }}
    >
      <Toast.Header>
        <FontAwesomeIcon
          icon={icon}
          className={join(['me-2', `text-${toast.bg}-emphasis`], ' ')}
        />
        <strong className="me-auto text-body">{toast.title}</strong>
        <small className="text-muted">{moment().format('LT')}</small>
      </Toast.Header>
      <Toast.Body className="text-white">{toast.body}</Toast.Body>
    </Toast>
  );
}
