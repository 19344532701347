import { InputSelect, InputText } from '@farmshare/ui-components';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FormikProps } from 'formik';
import { map } from 'lodash';
import { useEffect, type FocusEventHandler } from 'react';
import { Stack } from 'react-bootstrap';

import { StateManyQuery, useLocationOneLazyQuery } from 'lib/graphql';

interface ShipmentEstimateLocationProps<T> {
  formikProps: FormikProps<T>;
  prefix: string;
  states?: StateManyQuery;
}

export function ShipmentEstimateLocation<T>({
  formikProps: { setFieldError, setFieldValue },
  prefix,
  states,
}: ShipmentEstimateLocationProps<T>) {
  const [getLocation, getLocationOp] = useLocationOneLazyQuery();

  const onZipCodeBlur: FocusEventHandler<HTMLInputElement> = async (e) => {
    if (/^\d{5}$/.test(e.target.value)) {
      await getLocation({
        variables: { filter: { zip_code: e.target.value } },
      });
    } else {
      setFieldError(`${prefix}PostalCode`, 'Not a valid zip code.');
    }
  };

  useEffect(() => {
    if (getLocationOp.data?.locationOne) {
      setFieldValue(
        `${prefix}StateProvince`,
        getLocationOp.data.locationOne.state?.code,
      );
      setFieldValue(
        `${prefix}CityLocality`,
        getLocationOp.data.locationOne.city,
      );
    }
  }, [getLocationOp.data?.locationOne, prefix, setFieldValue]);

  if (getLocationOp.loading) {
    return (
      <div className="d-flex justify-content-center h-100">
        <div className="align-self-center">
          <FontAwesomeIcon icon={faSpinner} size="5x" spin />
        </div>
      </div>
    );
  }

  return (
    <Stack gap={3}>
      <InputText
        label="Zip"
        nameOveride={`${prefix}PostalCode`}
        type="text"
        onBlur={onZipCodeBlur}
        floatingLabel
        required
        max={5}
      />
      <InputSelect
        label="State"
        nameOveride={`${prefix}StateProvince`}
        options={map(states?.stateMany, (s) => ({
          value: s.code,
          label: s.name,
        }))}
        floatingLabel
      />
      <InputText
        label="City"
        nameOveride={`${prefix}CityLocality`}
        type="text"
        floatingLabel
      />
      <InputText
        label="Country"
        nameOveride={`${prefix}CountryCode`}
        type="text"
        hidden
      />
    </Stack>
  );
}
