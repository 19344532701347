import { Field, ErrorMessage, useFormikContext, FieldAttributes } from 'formik';
import { camelCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import { FloatingLabel, Form, FormControlProps } from 'react-bootstrap';

import { InputComponent } from '.';

export interface InputTextProps extends InputComponent {
  type:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week';
  min?: number;
  max?: number;
  step?: number;
}

export function InputText({
  label,
  disabled,
  hidden,
  required,
  type,
  className,
  hint,
  nameOveride,
  min,
  max,
  onBlur,
  step = 1,
  floatingLabel = false,
  showLabel = !hidden,
  readOnly = false,
}: InputTextProps) {
  const { errors } = useFormikContext<Record<string, string>>();

  const validate = useCallback(
    (value?: string | number) => {
      if (required) {
        if (typeof value === 'number') {
          if (isNaN(value)) {
            return `${label} is required.`;
          }
        } else if (!value || value === undefined) {
          return `${label} is required.`;
        }
      }
    },
    [label, required],
  );

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(
    () => ({
      name,
      disabled,
      hidden,
      min,
      max,
      onBlur,
      step,
      validate,
      readOnly,
      type,
      maxLength: max,
      required: required && !readOnly,
      plaintext: readOnly,
      as: Form.Control,
      placeholder: label?.replace(/[?:]/g, ''),
      isInvalid: !!errors[name],
    }),
    [
      disabled,
      errors,
      hidden,
      label,
      max,
      min,
      name,
      onBlur,
      readOnly,
      required,
      step,
      type,
      validate,
    ],
  );

  return (
    <Form.Group controlId={`form.${name}`} className={className}>
      {floatingLabel ? (
        <FloatingLabel
          controlId={`floatingInput.${name}`}
          label={
            <>
              {label}
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </>
          }
        >
          <Field {...fieldProps} />
        </FloatingLabel>
      ) : (
        <>
          {showLabel && (
            <Form.Label className="fw-light">
              {label}:
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </Form.Label>
          )}
          <Field {...fieldProps} />
        </>
      )}
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback type="invalid">{msg}</Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
