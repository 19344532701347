// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

export const environment = {
  production: false,
  wooCommerceUrl: 'https://dev-farmshare.dudka.agency',
  authAudience: 'https://api.farmshare.co',
  authDomain: 'dev-hw2ja6fxufte1kp3.us.auth0.com',
  authClientId: 'yWgEZ7Dmle9wZ91gpAEvxsMAeHFu9V9a',
};
