import { Request } from 'express';
import { includes, isNaN, isNull, isUndefined, mapValues, wrap } from 'lodash';
import moment, { Moment, isMoment } from 'moment';
import numeral, { Numeral, isNumeral } from 'numeral';

function convertMetersToMiles(meters?: number) {
  if (!meters || isNaN(meters)) {
    return 0;
  }

  return meters / 1609;
}

function getHostFromRequest(req: Request) {
  const host = `${req.protocol}://${req.hostname}${
    !includes([80, 443], req.socket.localPort) ? `:${req.socket.localPort}` : ''
  }`;
  return host;
}

function getMiddlewareUrl(isDev: boolean) {
  return `https://middleware${isDev ? '-dev' : ''}.farmshare.co`;
}

function isArrayNullOrEmpty(arr?: unknown[] | null) {
  if (isNullOrUndefined(arr) || arr?.length === 0) {
    return true;
  }

  return false;
}

function isNullOrUndefined(val?: unknown) {
  return isUndefined(val) || isNull(val);
}

function emptyStringToUndefined(str?: string): string | undefined {
  if (str === '') {
    return undefined;
  }
  return str;
}

function formatPhoneNumber(phoneNumber: string) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
}

function formatToCurrency(amount?: string | number | Numeral): string {
  if (isNullOrUndefined(amount)) {
    return '-';
  }

  if (isNumeral(amount)) {
    return (amount as Numeral).format('$0,0.00');
  }

  const nAmount = numeral(amount);

  if (!isNaN(nAmount)) {
    return nAmount.format('$0,0.00');
  }
  return '-';
}

type TDateType = 'short' | 'long';
function formatToDate(
  type: TDateType,
  date?: string | number | Date | Moment,
): string {
  const lookup: Record<TDateType, string> = { short: 'l', long: 'lll' };

  if (isNullOrUndefined(date)) {
    return '-';
  }

  if (isMoment(date)) {
    return date.format(lookup[type]);
  }

  const mDate = moment.utc(date);

  if (mDate.isValid()) {
    return mDate.format(lookup[type]);
  }
  return '-';
}

const formatToShortDate = wrap('short', formatToDate);
const formatToLongDate = wrap('long', formatToDate);

function removeEmptyProperties(obj: object) {
  return mapValues(obj, (v) => (v !== '' ? v : null));
}

export {
  convertMetersToMiles,
  emptyStringToUndefined,
  formatPhoneNumber,
  formatToCurrency,
  formatToLongDate,
  formatToShortDate,
  getHostFromRequest,
  getMiddlewareUrl,
  isArrayNullOrEmpty,
  isNullOrUndefined,
  removeEmptyProperties,
};
