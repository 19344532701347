import { isArrayNullOrEmpty } from '@farmshare/utils';
import { concat, join, map } from 'lodash';
import { useCallback } from 'react';
import { Col, Row } from 'react-bootstrap';

import { InputSelect, InputText } from '../inputs';

export interface AddressFormForm extends Record<string, string | undefined> {
  firstName?: string;
  lastName?: string;
  company?: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  postcode: string;
  email?: string;
  phone?: string;
}

export interface AddressFormProps {
  disabled?: boolean;
  floatingLabel?: boolean;
  prefixes?: string[];
  readOnly?: boolean;
  states?: {
    name: string;
    code: string;
  }[];
}

export function AddressForm({
  disabled,
  floatingLabel,
  prefixes,
  readOnly,
  states,
}: AddressFormProps) {
  const nameOveride = useCallback((name: string) => {
    if (!isArrayNullOrEmpty(prefixes)) {
      return join(concat(prefixes, name), '.');
    }

    return name;
  }, []);

  return (
    <Row className="g-3">
      <Col md={6}>
        <InputText
          label="First Name"
          nameOveride={nameOveride('firstName')}
          type="text"
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
          required
        />
      </Col>
      <Col md={6}>
        <InputText
          label="Last Name"
          nameOveride={nameOveride('lastName')}
          type="text"
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
          required
        />
      </Col>
      <Col md={5}>
        <InputText
          label="Email"
          nameOveride={nameOveride('email')}
          type="email"
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
          required
        />
      </Col>
      <Col md={4}>
        <InputText
          label="Company"
          nameOveride={nameOveride('company')}
          type="text"
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
        />
      </Col>
      <Col md={3}>
        <InputText
          label="Phone"
          nameOveride={nameOveride('phone')}
          type="tel"
          min={10}
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
          required
        />
      </Col>
      <Col md={7}>
        <InputText
          label="Address 1"
          nameOveride={nameOveride('address1')}
          type="text"
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
          required
        />
      </Col>
      <Col md={5}>
        <InputText
          label="Address 2"
          nameOveride={nameOveride('address2')}
          type="text"
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
        />
      </Col>
      <Col md={5}>
        <InputText
          label="City"
          nameOveride={nameOveride('city')}
          type="text"
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
          required
        />
      </Col>
      <Col md={4}>
        <InputSelect
          label="State"
          options={map(states, (s) => ({
            value: s.code,
            label: s.name,
          }))}
          nameOveride={nameOveride('state')}
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
          required
        />
      </Col>
      <Col md={3}>
        <InputText
          label="Zip"
          nameOveride={nameOveride('postcode')}
          type="text"
          floatingLabel={floatingLabel}
          readOnly={readOnly}
          disabled={disabled}
          required
        />
      </Col>
    </Row>
  );
}
