import { useAuth0 } from '@auth0/auth0-react';
import {
  Loading,
  ModalProvider,
  SiteFooter,
  SiteHeader,
  SiteHeaderNavLink,
  ToastrProvider,
} from '@farmshare/ui-components';
import { environment } from 'environments/environment';
import { concat } from 'lodash';
import { Suspense, useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { themeState, userState } from 'state';

import { AuthApolloProvider } from 'components/auth-apollo-provider/auth-apollo-provider';

import { useUserLazyQuery, type User } from 'lib/graphql';

export function App() {
  const { isAuthenticated, isLoading, loginWithRedirect, logout } = useAuth0();
  const [theme, setTheme] = useRecoilState(themeState);
  const [user, setUserState] = useRecoilState(userState);

  const [getUser, getUserOperations] = useUserLazyQuery();

  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (getUserOperations.data?.user) {
      setUserState(getUserOperations.data.user as User);
    }
  }, [getUserOperations.data?.user]);

  const navLinks = useMemo(() => {
    let _links: SiteHeaderNavLink[] = [{ label: 'Home', href: '/' }];

    if (isAuthenticated) {
      _links = concat(_links, [
        { label: 'Orders', href: '/orders' },
        { label: 'Shipments', href: '/shipments' },
        { label: 'Payouts', href: '/payouts' },
        { label: 'Vendors', href: '/vendors' },
        { label: 'KPIs', href: '/kpi' },
      ]);
    }

    return _links;
  }, [isAuthenticated]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Suspense fallback={<Loading />}>
      <ModalProvider>
        <ToastrProvider>
          <SiteHeader<User>
            user={user}
            isProduction={environment.production}
            title="Middleware"
            navLinks={navLinks}
            loginFn={loginWithRedirect}
            logoutFn={() =>
              logout({ logoutParams: { returnTo: window.location.origin } })
            }
          />
          <Container className="mt-3 mb-5">
            <Outlet />
          </Container>
          <SiteFooter theme={theme} setTheme={setTheme} />
        </ToastrProvider>
      </ModalProvider>
    </Suspense>
  );
}
