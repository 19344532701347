import { ErrorMessage, Field, FieldAttributes, useFormikContext } from 'formik';
import { camelCase } from 'lodash';
import { useCallback, useMemo } from 'react';
import { FloatingLabel, Form, FormControlProps } from 'react-bootstrap';

import type { InputComponent } from '.';

export interface InputTextAreaProps extends InputComponent {
  rows?: number;
}

export function InputTextArea({
  className,
  disabled,
  hidden,
  floatingLabel,
  label,
  hint,
  nameOveride,
  rows = 1,
  required,
  readOnly,
  showLabel,
}: InputTextAreaProps) {
  const { errors } = useFormikContext<Record<string, string>>();

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const validate = useCallback(
    (value: string | number | undefined) => {
      if (required && (!value || value === undefined)) {
        return `${label} is required.`;
      }
    },
    [label, required],
  );

  const Textarea = useCallback(
    (props: FormControlProps) => <Form.Control as="textarea" {...props} />,
    [],
  );

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(
    () => ({
      name,
      disabled,
      hidden,
      validate,
      rows,
      readOnly,
      required: required && !readOnly,
      plaintext: readOnly,
      as: Textarea,
      placeholder: label?.replace(/[?:]/g, ''),
      isInvalid: !!errors[name],
    }),
    [disabled, errors, hidden, label, name, readOnly, required, validate],
  );

  return (
    <Form.Group controlId={`form.${name}`} className={className}>
      {floatingLabel ? (
        <FloatingLabel
          controlId={`floatingInput.${name}`}
          label={
            <>
              {label}
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </>
          }
        >
          <Field {...fieldProps} style={{ height: `${55 * rows}px` }} />
        </FloatingLabel>
      ) : (
        <>
          {showLabel && (
            <Form.Label className="fw-light">
              {label}:
              {fieldProps.required && (
                <sup className="text-danger fw-bold">&nbsp;*</sup>
              )}
            </Form.Label>
          )}
          <Field {...fieldProps} />
        </>
      )}
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback type="invalid">{msg}</Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
