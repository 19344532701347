import { PaginationTablePage } from '@farmshare/ui-components';
import { formatToCurrency, formatToShortDate } from '@farmshare/utils';
// import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { filter, map, startCase } from 'lodash';
import { Link } from 'react-router-dom';

import {
  EnumVendorPayoutStatus,
  VendorPayout,
  VendorPayoutPagination,
  VendorPayoutPaginationQuery,
  VendorPayoutPaginationQueryVariables,
  SortFindManyVendorPayoutInput,
  useVendorPayoutPaginationLazyQuery,
  Shipment,
  // useVendorPayoutCreateOneMutation,
} from 'lib/graphql';

import { VendorPayoutActionButtons } from 'views/vendor-payouts/action-buttons';

// import {
//   VendorPayoutModal,
//   type VendorPayoutModalForm,
// } from './_views/vendor-payout-modal';

export default function Payouts() {
  // const { save } = useModal();

  // const [createPayout, createPayoutOp] = useVendorPayoutCreateOneMutation();

  return (
    <PaginationTablePage<
      Pick<
        VendorPayout,
        'status' | 'subTotal' | 'shipping' | 'total' | 'date_created'
      > & {
        shipment?: Pick<
          Shipment,
          '_id' | 'shipment_status' | 'total' | 'fee' | 'order' | 'vendor'
        > | null;
      },
      VendorPayoutPagination,
      VendorPayoutPaginationQuery,
      VendorPayoutPaginationQueryVariables,
      SortFindManyVendorPayoutInput
    >
      // actionButtons={({ refetch }) => [
      //   {
      //     content: 'Add Payout',
      //     icon: faPlus,
      //     isLoading: createPayoutOp.loading,
      //     onClick: () => {
      //       save<VendorPayoutModalForm>({
      //         type: 'save',
      //         title: 'Add Payout',
      //         icon: faPlus,
      //         isLoading: createPayoutOp.loading,
      //         initialValues: {
      //           order: '',
      //           vendor: '',
      //           subTotal: 0,
      //           shipping: 0,
      //           total: 0,
      //         },
      //         body: (fP) => <VendorPayoutModal fP={fP} />,
      //         onSubmit: async (values) => {
      //           await createPayout({
      //             variables: {
      //               record: {
      //                 date_created: new Date(),
      //                 shipment: null,
      //                 subTotal: values.subTotal,
      //                 shipping: values.shipping,
      //                 status: EnumVendorPayoutStatus.Unpaid,
      //                 total: values.total,
      //               },
      //             },
      //           });
      //           refetch();
      //         },
      //       });
      //     },
      //   },
      // ]}
      paginationQuery={useVendorPayoutPaginationLazyQuery()}
      filters={map(EnumVendorPayoutStatus, (e) => ({
        label: e.toString(),
        value: e,
      }))}
      buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
        const activeFilters = filter(allFilters, (f) => f.isActive || false);

        if (activeFilters) {
          return {
            filter: {
              OR: map(activeFilters, (a) => ({
                status: a.value as EnumVendorPayoutStatus,
              })),
            },
            sort: defaultSort,
            page,
            perPage,
          };
        }
        return { sort: defaultSort, page, perPage };
      }}
      defaultFilters={[EnumVendorPayoutStatus.Unpaid]}
      defaultSort={SortFindManyVendorPayoutInput.DateCreatedDesc}
      dataAccessor={(a) => a.vendorPayoutPagination as VendorPayoutPagination}
      title="Payouts"
      columns={[
        {
          label: 'Order',
          formatter: (row) => (
            <Link
              to={`/orders/${row.shipment?.order?.external_id}#${row.shipment?._id}`}
            >
              {row.shipment?.order?.external_id}
            </Link>
          ),
        },
        {
          label: 'Date Created',
          field: 'date_created',
          formatter: (row) => formatToShortDate(row.date_created),
        },
        { label: 'Vendor', field: 'shipment.vendor.shop_name' },
        {
          label: 'Payout Status',
          field: 'status',
          formatter: (row) => startCase(row.status),
        },
        {
          label: 'Shipment Status',
          field: 'shipment.shipment_status',
          formatter: (row) => startCase(row.shipment?.shipment_status),
        },
        {
          label: 'SubTotal',
          field: 'shipment.total',
          formatter: (row) => formatToCurrency(row.shipment?.total),
        },
        {
          label: 'Fee',
          field: 'shipment.fee',
          formatter: (row) => formatToCurrency(row.shipment?.fee),
          minimumBreakpoint: 'lg',
        },
        {
          label: 'Net',
          field: 'subTotal',
          formatter: (row) => formatToCurrency(row.subTotal),
          minimumBreakpoint: 'lg',
        },
        {
          label: 'Shipping',
          field: 'shipping',
          formatter: (row) => formatToCurrency(row.shipping),
          minimumBreakpoint: 'lg',
        },
        {
          label: 'Total',
          field: 'total',
          formatter: (row) => formatToCurrency(row.total),
        },
        {
          formatter: (vendorPayout, _, op) => (
            <VendorPayoutActionButtons
              vendorPayout={vendorPayout as VendorPayout}
              op={op}
            />
          ),
        },
      ]}
    />
  );
}
