import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormikProps } from 'formik';
import { isFunction, join } from 'lodash';
import { useCallback } from 'react';
import { Alert, Modal } from 'react-bootstrap';

import { IModal, IModalProperties } from './modal-renderer';
import { Button } from '../button/button';
import { FormValues } from '../form/form';

interface IModalInnerPropsBase<TForm extends FormValues<TForm>> {
  modal: IModal<TForm>;
  modalProps: IModalProperties;
  formikProps?: FormikProps<TForm>;
}

interface IModalInnerPropsSave<TForm extends FormValues<TForm>>
  extends IModalInnerPropsBase<TForm> {
  formikProps: FormikProps<TForm>;
}

export type IModalInnerProps<TForm extends FormValues<TForm>> =
  | IModalInnerPropsBase<TForm>
  | IModalInnerPropsSave<TForm>;

export default function ModalInner<TForm extends FormValues<TForm>>(
  props: IModalInnerProps<TForm>,
) {
  const renderModalBody = useCallback(() => {
    if (isFunction(props.modal.body)) {
      if (props.formikProps) {
        return props.modal.body(props.formikProps);
      }
      throw new Error('Formik Props not present.');
    }
    return props.modal.body;
  }, [props.formikProps, props.modal]);

  return (
    <>
      <Modal.Header
        className={props.modalProps.headerClassNames.join(' ')}
        closeButton
      >
        <Modal.Title>
          <FontAwesomeIcon
            icon={props.modal.icon ? props.modal.icon : props.modalProps.icon}
            className={join(['me-3', props.modal.iconClassName], ' ')}
          />
          {props.modal.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-body">
        {props.modal.error && (
          <Alert variant="danger">{props.modal.error}</Alert>
        )}
        {renderModalBody()}
      </Modal.Body>
      <Modal.Footer className="bg-body-tertiary">
        {props.modalProps.buttons.map((button, idx) => (
          <Button key={idx} {...button} />
        ))}
      </Modal.Footer>
    </>
  );
}
