import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { includes, map } from 'lodash';
import {
  type ButtonProps as RBButtonProps,
  Dropdown,
  Stack,
} from 'react-bootstrap';
import type { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';

import { useBreakpoint } from '../../hooks/use-breakpoint/use-breakpoint';
import { Button, ButtonProps } from '../button/button';

export interface ActionButtonsProps {
  buttons: ButtonProps[];
}

export function ActionButtons({ buttons }: ActionButtonsProps) {
  const breakpoint = useBreakpoint();

  return includes(['lg', 'xl'], breakpoint) ? (
    <Stack direction="horizontal" gap={2} className="justify-content-end py-1">
      {map(buttons, (b, i) => (
        <Button {...b} size="sm" target="_blank" key={i} />
      ))}
    </Stack>
  ) : (
    <Dropdown align="end" className="my-auto">
      <Dropdown.Toggle
        icon={faEllipsis}
        variant="primary"
        size="lg"
        as={FontAwesomeIcon}
        className="bg-primary text-white border border-primary rounded px-2"
        style={{ cursor: 'pointer', marginTop: '1px' }}
      >
        <span className="d-none d-md-inline">Actions</span>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {map(buttons, (b, idx) => {
          const props: DropdownItemProps & RBButtonProps = {
            key: idx,
          };

          if (b.href) {
            props.href = b.href;
            props.target = '_blank';
          }

          if (b.onClick) {
            props.onClick = b.onClick;
          }

          return (
            <Dropdown.Item {...props}>
              {b.icon && (
                <FontAwesomeIcon icon={b.icon} style={{ minWidth: '25px' }} />
              )}
              {b.content}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
}
