import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { findIndex, join, map } from 'lodash';
import { ReactNode, useCallback, useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';

interface ProgressStep {
  key: string;
  label: string;
  nextAction?: ReactNode;
}

export interface ProgressDisplayProps {
  steps: ProgressStep[];
  currentStep: string;
}

export function ProgressDisplay(props: ProgressDisplayProps) {
  const currentStepIdx = useMemo(
    () => findIndex(props.steps, { key: props.currentStep }),
    [props.currentStep, props.steps],
  );

  const now = useMemo(
    () => ((currentStepIdx + 1) / props.steps.length) * 100,
    [currentStepIdx, props.steps.length],
  );

  const stepRenderer = useCallback(
    (step: ProgressStep, idx: number) => {
      const stepProp: {
        icon: FontAwesomeIconProps['icon'];
        iconClass: string;
        labelClass: string;
      } = {
        icon: faCircle,
        iconClass: '',
        labelClass: '',
      };

      if (idx <= currentStepIdx) {
        stepProp.icon = faCheckCircle;
        stepProp.iconClass = 'text-primary';
        stepProp.labelClass = 'text-primary';
      }

      return (
        <div
          key={step.key}
          className="mt-2 text-center d-flex flex-column fw-light"
        >
          <div>
            <FontAwesomeIcon
              icon={stepProp.icon}
              className={stepProp.iconClass}
            />
          </div>
          {step.nextAction && idx === currentStepIdx + 1 ? (
            step.nextAction
          ) : (
            <div
              className={join(
                [stepProp.labelClass, 'd-flex', 'flex-grow-1'],
                ' ',
              )}
            >
              <div className="m-auto d-md-none small">{step.label}</div>
              <div className="m-auto d-none d-md-block">{step.label}</div>
            </div>
          )}
        </div>
      );
    },
    [currentStepIdx],
  );

  return (
    <div className="m-0 m-md-3">
      <ProgressBar now={now} variant="primary" />
      <div className="d-flex justify-content-between align-items-center">
        {map(props.steps, stepRenderer)}
      </div>
    </div>
  );
}

export default ProgressDisplay;
