import { Table } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { sortBy, startCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { Shipment } from 'lib/graphql';

interface VendorDetailsOrdersProps {
  shipments: Shipment[];
}

export default function VendorDetailsShipments(
  props: VendorDetailsOrdersProps,
) {
  return (
    <Table<
      Pick<
        Shipment,
        | 'external_id'
        | 'date_created'
        | 'shipment_status'
        | 'order'
        | 'line_items'
        | 'total'
      >
    >
      rows={sortBy(props.shipments, (p) => p.external_id)}
      columns={[
        {
          label: 'Id',
          field: 'external_id',
          formatter: (row) => (
            <Link to={`/orders/${row.external_id}`}>{row.external_id}</Link>
          ),
        },
        {
          label: 'Date Created',
          field: 'date_created',
          formatter: (row) => moment(row.date_created).format('LLL'),
        },
        {
          label: 'Status',
          field: 'shipment_status',
          formatter: (row) => startCase(row.shipment_status),
        },
        { label: 'Customer', field: 'order.customer.full_name' },
        {
          label: 'Items',
          field: 'line_items',
          formatter: (row) => row.line_items.length,
        },
        {
          label: 'Total',
          field: 'total',
          formatter: (row) => formatToCurrency(row.total),
        },
      ]}
    />
  );
}
