import { faLightbulb, faMoon } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEventHandler, useCallback, useEffect } from 'react';
import { Form } from 'react-bootstrap';

export type Theme = 'light' | 'dark';

interface ThemeSelectorProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

export function ThemeSelector({ theme, setTheme }: ThemeSelectorProps) {
  useEffect(() => {
    const _theme = localStorage.getItem('theme');

    if (_theme === 'dark') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }, [setTheme]);

  useEffect(() => {
    document.getElementById('navbar')?.setAttribute('data-bs-theme', theme);
    document.documentElement.setAttribute('data-bs-theme', theme);
  }, [theme]);

  const handleThemeChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const theme: Theme = e.target.checked ? 'dark' : 'light';
      setTheme(theme);
      localStorage.setItem('theme', theme);
    },
    [setTheme],
  );

  return (
    <div className="d-flex fw-light small text-muted">
      <div className="me-2">
        Light&nbsp;
        <FontAwesomeIcon icon={faLightbulb} size="sm" />
        &nbsp;
      </div>
      <Form.Switch
        name="theme"
        onChange={handleThemeChange}
        checked={theme === 'dark'}
      />
      <div className="ms-1">
        <FontAwesomeIcon icon={faMoon} size="sm" />
        &nbsp;Dark
      </div>
    </div>
  );
}

export default ThemeSelector;
