import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';

export interface ErrorProps {
  error?: Error;
  logo?: ReactNode;
}

export function Error(props: ErrorProps) {
  return (
    <div className="text-center">
      {props.logo}
      <div className="position-absolute top-0 start-0 text-danger d-flex w-100 vh-100 align-content-center align-items-center z-n1">
        <div className="align-items-center d-flex flex-column mx-auto w-50">
          <FontAwesomeIcon icon={faXmark} size="5x" className="mb-3" />
          <div className="fw-bolder fs-4">Oops.</div>
          <pre className="fs-5 text-danger-emphasis">
            {props.error?.message}
          </pre>
        </div>
      </div>
    </div>
  );
}
