import {
  Button,
  DataDetailList,
  Table,
  useModal,
} from '@farmshare/ui-components';
import { formatToShortDate, isArrayNullOrEmpty } from '@farmshare/utils';
import { faDolly, faTruck } from '@fortawesome/free-solid-svg-icons';
import { map, sortBy } from 'lodash';
import { Stack } from 'react-bootstrap';

import type { Package } from 'lib/graphql';

type TPackage = Pick<
  Package,
  | '_id'
  | 'tracking_number'
  | 'tracking_page'
  | 'status_description'
  | 'events'
  | 'dimensions'
  | 'weight'
>;

interface ShipmentPackagesProps {
  packages: Package[];
}

export function ShipmentPackages({ packages }: ShipmentPackagesProps) {
  const { ask, info } = useModal();

  // const sendLink = useCallback(
  //   (row: TPackage) =>
  //     `mailto:${shipment?.vendor?.address.email}?subject=Order #${orderId} | Shipping Label&body=${row.label_url}&cc=info@farmshare.co`,
  //   [orderId, shipment?.vendor?.address.email],
  // );

  return (
    <Table<TPackage>
      rows={packages}
      columns={[
        {
          formatter(_, rowNumber) {
            return `${rowNumber}.`;
          },
          minimumBreakpoint: 'lg',
        },
        {
          label: 'Tracking Number',
          field: 'tracking_number',
          minimumBreakpoint: 'lg',
        },
        {
          label: 'Status',
          field: 'status_description',
        },
        {
          label: 'Weight',
          field: 'weight',
          formatter(row) {
            return (
              <>
                {row.weight}
                <small className="text-muted">&nbsp;(lbs.)</small>
              </>
            );
          },
        },
        {
          label: 'Dimensions',
          field: 'dimensions',
          formatter({ dimensions }) {
            return (
              <>
                {`${dimensions.length}x${dimensions.width}x${dimensions.height}`}
                <small className="text-muted">&nbsp;(in.)</small>
              </>
            );
          },
          minimumBreakpoint: 'md',
        },
        {
          label: 'Actions',
          formatter(row) {
            return (
              <Stack direction="horizontal" gap={{ xs: 1, lg: 2 }}>
                <Button
                  content={<span className="d-none d-md-inline">Events</span>}
                  icon={faDolly}
                  size="sm"
                  onClick={() =>
                    info({
                      type: 'info',
                      title: 'Tracking Events',
                      body: (
                        <div>
                          {isArrayNullOrEmpty(row.events) ? (
                            <div className="fst-italic">
                              No tracking events have occurred yet.
                            </div>
                          ) : (
                            <DataDetailList
                              rows={map(
                                sortBy(row.events, (e) => e.occurred_at),
                                (e) => ({
                                  label: formatToShortDate(e.occurred_at),
                                  value: e.description,
                                }),
                              )}
                            />
                          )}
                        </div>
                      ),
                    })
                  }
                />
                {row.tracking_page && (
                  <Button
                    content={<span className="d-none d-md-inline">Track</span>}
                    icon={faTruck}
                    size="sm"
                    href={row.tracking_page}
                    target="_blank"
                  />
                )}
              </Stack>
            );
          },
        },
      ]}
    />
  );
}
