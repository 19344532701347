import { Error } from '@farmshare/ui-components';
import { isRouteErrorResponse, useRouteError } from 'react-router-dom';

export default function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    switch (error.status) {
      case 404:
        return <Error error={{ name: '404', message: 'Page not found.' }} />;
      default:
        return (
          <Error
            error={{ name: 'Unspecified', message: 'We encountered an error!' }}
          />
        );
    }
  }
}
