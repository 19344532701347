import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ErrorMessage, Field, FieldAttributes, useFormikContext } from 'formik';
import { camelCase, isNumber } from 'lodash';
import { useCallback, useMemo } from 'react';
import { Button, Form, FormControlProps } from 'react-bootstrap';

import { InputComponent } from '.';

interface InputQuantityProps extends InputComponent {
  min?: number;
  max?: number;
  step?: number;
}

export function InputQuantity({
  className,
  disabled,
  hidden,
  hint,
  label,
  max,
  min,
  nameOveride,
  readOnly,
  required,
  step = 1,
}: InputQuantityProps) {
  const { errors, handleChange, values, setFieldValue, setFieldError } =
    useFormikContext<Record<string, number>>();

  const name = useMemo(
    () => nameOveride ?? camelCase(label),
    [label, nameOveride],
  );

  const changeValue = useCallback(
    (type: 'INCREMENT' | 'DECREMENT') => {
      let quantity: number = values[name] || 0;

      switch (type) {
        case 'INCREMENT':
          if (isNumber(max)) {
            if (quantity === max) {
              setFieldError(name, `${max} is the maximum value.`);
            } else if (min && quantity < min) {
              quantity = min;
            } else if (quantity + step > max) {
              quantity = max;
            } else {
              quantity += step;
            }
          } else {
            quantity += step;
          }
          break;

        case 'DECREMENT':
          if (isNumber(min)) {
            if (quantity === min) {
              setFieldError(name, `${min} is the minimum value.`);
            } else if (quantity - step < min) {
              quantity = min;
            } else {
              quantity -= step;
            }
          } else {
            quantity -= step;
          }
          break;
      }

      setFieldValue(name, quantity, true);
    },
    [values, name, min, max, step, setFieldError, setFieldValue],
  );

  const fieldProps: FieldAttributes<FormControlProps> = useMemo(
    () => ({
      name,
      disabled,
      hidden,
      min,
      max,
      step,
      readOnly,
      required: required && !readOnly,
      plaintext: readOnly,
      as: Form.Control,
      type: 'number',
      placeholder: label?.replace(/[?:]/g, ''),
      isInvalid: !!errors[name],
      className: 'text-end',
      value: values[name],
      onChange: handleChange,
    }),
    [
      disabled,
      errors,
      hidden,
      label,
      max,
      min,
      name,
      readOnly,
      required,
      step,
      values,
      handleChange,
    ],
  );

  return (
    <Form.Group controlId={`form.${name}`} className={className}>
      {label && (
        <Form.Label className="fw-light">
          {label}:
          {fieldProps.required && (
            <sup className="text-danger fw-bold">&nbsp;*</sup>
          )}
        </Form.Label>
      )}
      <div className="d-flex">
        <Button variant="ghost" onClick={() => changeValue('DECREMENT')}>
          <FontAwesomeIcon icon={faMinus} />
        </Button>
        <Field {...fieldProps} />
        <Button variant="ghost" onClick={() => changeValue('INCREMENT')}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      {hint && <Form.Text>{hint}</Form.Text>}
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <Form.Control.Feedback type="invalid">{msg}</Form.Control.Feedback>
        )}
      />
    </Form.Group>
  );
}
