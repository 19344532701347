import { map } from 'lodash';
import { ReactNode } from 'react';
import { Table } from 'react-bootstrap';

export interface DataDetailListProps {
  heading?: ReactNode;
  rows: { label: ReactNode; value?: ReactNode }[];
}

export function DataDetailList({ heading, rows }: DataDetailListProps) {
  return (
    <div>
      {heading && (
        <>
          <h6>{heading}</h6>
          <hr />
        </>
      )}
      <Table size="sm" borderless>
        <tbody>
          {map(rows, (row, idx) => (
            <tr key={idx} className="align-middle">
              <td
                className="bg-transparent fw-light small text-enad text-muted text-nowrap"
                // style={{ width: '0px' }}
              >
                {row.label}:&nbsp;
              </td>
              <td className="bg-transparent">{row.value || '-'}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
