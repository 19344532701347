import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown, DropdownButton, Image } from 'react-bootstrap';

export interface AuthProps<TUser> {
  user: TUser;
  logoutFn: () => Promise<void>;
}

export function Auth<
  TUser extends { first_name?: string; picture_url?: string | null },
>(props: AuthProps<TUser>) {
  return (
    <div>
      <DropdownButton
        variant="ghost"
        title={
          <div className="d-inline-flex align-items-center">
            {props.user.picture_url && (
              <span className="me-2">
                <Image
                  src={props.user.picture_url}
                  referrerPolicy="no-referrer"
                  height="30px"
                  width="30px"
                  roundedCircle
                  fluid
                />
              </span>
            )}
            <span className="fw-light me-1">Welcome,</span>
            <span className="fw-bold">{props.user.first_name}</span>
          </div>
        }
      >
        <Dropdown.Item onClick={props.logoutFn}>
          <FontAwesomeIcon icon={faRightFromBracket} />
          &nbsp;Logout
        </Dropdown.Item>
      </DropdownButton>
    </div>
  );
}

export default Auth;
