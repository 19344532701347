import {
  Auth0Provider,
  Auth0ProviderOptions,
  withAuthenticationRequired,
} from '@auth0/auth0-react';
import { App } from 'app';
import { environment } from 'environments/environment';
import moment from 'moment';
import { ComponentType, StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import {
  RouterProvider,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { AuthApolloProvider } from 'components/auth-apollo-provider/auth-apollo-provider';

import ErrorBoundary from 'pages/error';
import Home from 'pages/home';
import KPI from 'pages/kpi';
import Orders from 'pages/orders';
import OrderDetails from 'pages/orders/details';
import Payouts from 'pages/payouts';
import Shipments from 'pages/shipments';
import ShipmentEstimates from 'pages/shipments/estimate';
import Vendors from 'pages/vendors';
import VendorDetails from 'pages/vendors/details';

moment.updateLocale('en', {
  week: { dow: 1, doy: 7 },
});

// create root
const root = createRoot(document.getElementById('root') as HTMLElement);

// protected route component
const ProtectedRoute = ({
  component,
}: {
  component: ComponentType;
  args?: unknown;
}) => {
  const Component = withAuthenticationRequired(component);
  return <Component />;
};

// auth provider
const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: Auth0ProviderOptions) => {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      onRedirectCallback={(appState) => {
        navigate((appState && appState.returnTo) || window.location.pathname);
      }}
      cacheLocation="localstorage"
      useRefreshTokensFallback
      useRefreshTokens
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

// router
const router = createBrowserRouter([
  {
    element: (
      <Auth0ProviderWithRedirectCallback
        domain={environment.authDomain}
        clientId={environment.authClientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: environment.authAudience,
        }}
        useCookiesForTransactions
        useRefreshTokens
        useRefreshTokensFallback
      >
        <AuthApolloProvider>
          <App />
        </AuthApolloProvider>
      </Auth0ProviderWithRedirectCallback>
    ),
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, element: <Home /> },
      { path: '/kpi', element: <ProtectedRoute component={KPI} /> },
      { path: '/orders', element: <ProtectedRoute component={Orders} /> },
      {
        path: '/orders/:orderId',
        element: <ProtectedRoute component={OrderDetails} />,
      },
      { path: '/shipments', element: <ProtectedRoute component={Shipments} /> },
      {
        path: '/shipments/estimate',
        element: <ProtectedRoute component={ShipmentEstimates} />,
      },
      { path: '/payouts', element: <ProtectedRoute component={Payouts} /> },
      { path: '/vendors', element: <ProtectedRoute component={Vendors} /> },
      {
        path: '/vendors/:vendorId',
        element: <ProtectedRoute component={VendorDetails} />,
      },
    ],
  },
]);

root.render(
  <StrictMode>
    <RecoilRoot>
      <RouterProvider router={router} />
    </RecoilRoot>
  </StrictMode>,
);
