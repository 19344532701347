import { DataDetailList } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { Col, Row } from 'react-bootstrap';

import { VendorPayout } from 'lib/graphql';

import { ShipmentItems } from 'views/shipment-details/shipment-items';

interface VendorPayoutDetailsProps {
  vendorPayout: VendorPayout;
}

export function VendorPayoutDetails({
  vendorPayout,
}: VendorPayoutDetailsProps) {
  return (
    <div>
      <Row md={2}>
        <Col>
          <DataDetailList
            rows={[
              {
                label: 'Order #',
                value: vendorPayout.shipment?.order?.external_id,
              },
              {
                label: 'Vendor',
                value: vendorPayout.shipment?.vendor?.shop_name,
              },
            ]}
          />
        </Col>
        <Col>
          <DataDetailList
            rows={[
              {
                label: 'Payouts Enabled',
                value: vendorPayout.shipment?.vendor?.account?.payouts_enabled
                  ? 'Yes'
                  : 'No',
              },
              {
                label: 'Amount',
                value: formatToCurrency(vendorPayout.total),
              },
            ]}
          />
        </Col>
      </Row>
      <hr />
      <h6 className="mb-3">Items Purchased:</h6>
      {vendorPayout.shipment?.line_items && (
        <ShipmentItems line_items={vendorPayout.shipment.line_items} />
      )}
    </div>
  );
}
