import {
  Dictionary,
  ValueIteratee,
  countBy,
  entries,
  flatMap,
  groupBy,
  map,
  meanBy,
  reduce,
  sumBy,
  toArray,
  uniqBy,
} from 'lodash';
import moment from 'moment';

import type { KPIDataSeries } from 'views/kpi-view';

import { KpiData, Vendor } from './graphql';

export interface KpiCalcs {
  sales: KPIDataSeries;
  totalSales: KPIDataSeries;
  activeVendors: KPIDataSeries;
  vendorsWithSales: KPIDataSeries;
  repeatCustomers: KPIDataSeries;
  newCustomers: KPIDataSeries;
  newVendors: KPIDataSeries;
  aov: KPIDataSeries;
  salesPerVendor: KPIDataSeries;
}

function reducer(
  collection: Dictionary<KpiData[]>,
  fn: (
    collection: KpiData[],
    iteratee: ValueIteratee<KpiData>,
  ) => Dictionary<number>,
  iteratee: ValueIteratee<KpiData>,
) {
  return reduce<Dictionary<KpiData[]>, Record<string, Dictionary<number>>>(
    collection,
    (aggregate, weekData, idx) => {
      aggregate[idx] = fn(weekData, iteratee);
      return aggregate;
    },
    {},
  );
}

const DATE_FORMAT = 'M/D/YY';

export function kpiCalcs(
  data: KpiData[],
  interval: 'day' | 'week' | 'month' | 'quarter',
  vendors: Pick<Vendor, 'date_created' | 'enabled'>[],
  activeVendors = 1,
): KpiCalcs {
  const weeklyPartition: Dictionary<KpiData[]> = groupBy(data, (d) =>
    moment
      .utc(d.order?.date)
      .startOf(interval)
      .format(DATE_FORMAT),
  );

  const customer_segments: Record<string, Dictionary<number>> = reducer(
    weeklyPartition,
    countBy,
    (kpi) => (kpi.order?.is_customer_first_order ? 'new' : 'repeat'),
  );

  const getOrderSubtotals = (kData: KpiData) =>
    sumBy(kData.vendors, (v) => sumBy(v?.products, (p) => p?.total || 0));

  return {
    sales: {
      label: 'Total Sales (#)',
      data: map(
        reducer(weeklyPartition, countBy, (kpi) => kpi.order?.id),
        (k, v) => ({ date: v, count: sumBy(toArray(k)) }),
      ),
    },
    salesPerVendor: {
      label: 'Sales/Vendor',
      data: map(
        reducer(weeklyPartition, countBy, (kpi) => kpi.order?.id),
        (k, v) => ({
          date: v,
          count: sumBy(toArray(k)) / activeVendors,
        }),
      ),
    },
    totalSales: {
      label: 'Total Sales ($)',
      data: map(weeklyPartition, (k, v) => ({
        date: v,
        count: sumBy(k, getOrderSubtotals),
      })),
    },
    aov: {
      label: 'AOV',
      data: map(weeklyPartition, (k, v) => ({
        date: v,
        count: meanBy(k, getOrderSubtotals),
      })),
    },
    activeVendors: {
      label: 'Active Vendors',
      data: map(weeklyPartition, (k, v) => ({
        date: v,
        count: uniqBy(
          flatMap(k, (a) => a.vendors),
          (s) => s?.id,
        ).length,
      })),
    },
    newVendors: {
      label: 'New Vendors',
      data: map(
        groupBy(vendors, (v) =>
          moment.utc(v.date_created).startOf(interval).format(DATE_FORMAT),
        ),
        (value, key) => ({ date: key, count: value.length }),
      ),
    },
    vendorsWithSales: {
      label: 'Active Customers',
      data: map(
        reducer(weeklyPartition, countBy, (kpi) => kpi.customer?.name),
        (k, v) => ({ date: v, count: entries(k).length }),
      ),
    },
    repeatCustomers: {
      label: 'Repeat Customers',
      data: map(customer_segments, (k, v) => ({
        date: v,
        count: k['repeat'] || 0,
      })),
    },
    newCustomers: {
      label: 'New Customers',
      data: map(customer_segments, (k, v) => ({
        date: v,
        count: k['new'] || 0,
      })),
    },
  };
}
