import { formatPhoneNumber } from '@farmshare/utils';
import { filter } from 'lodash';

import { AddressFragmentFragment } from 'lib/graphql';

interface ArrayProps {
  label: string;
  value?: string | null;
}

export function addressFieldsArray(
  address: AddressFragmentFragment,
  includeEmail = true,
  includePhone = true,
): ArrayProps[] {
  const arr: ArrayProps[] = [
    {
      label: 'Name',
      value: `${address.first_name} ${address.last_name}`,
    },
    {
      label: 'Company',
      value: address.company,
    },
    {
      label: 'Address 1',
      value: address.address_1,
    },
    {
      label: 'Address 2',
      value: address.address_2,
    },
    {
      label: 'City',
      value: address.city,
    },
    {
      label: 'State',
      value: address.state,
    },
    {
      label: 'Zip',
      value: address.postcode,
    },
  ];

  if (includeEmail) {
    arr.push({
      label: 'Email',
      value: address.email,
    });
  }

  if (includePhone) {
    arr.push({
      label: 'Phone',
      value: address.phone ? formatPhoneNumber(address.phone) : null,
    });
  }

  return filter(arr, (a) => !!a.value);
}
