import { ExternalLink, Table } from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sortBy, startCase } from 'lodash';
import { Link } from 'react-router-dom';

import { Product } from 'lib/graphql';

interface VendorDetailsOrdersProps {
  products: Product[];
}

export default function VendorDetailsProducts(props: VendorDetailsOrdersProps) {
  return (
    <Table<Product>
      rows={sortBy(props.products, (p) => p.external_id)}
      columns={[
        {
          label: 'Name',
          field: 'name',
          formatter: (row) => (
            <Link to={`/products/${row.external_id}`}>{row.name}</Link>
          ),
        },
        {
          label: 'Link',
          field: 'permalink',
          formatter(row) {
            return row.permalink ? (
              <ExternalLink href={row.permalink} text="farmshare.co" />
            ) : (
              ''
            );
          },
        },
        {
          label: 'Enabled',
          field: 'enabled',
          formatter: (row) =>
            row.enabled && (
              <FontAwesomeIcon icon={faCheck} className="text-primary" />
            ),
        },
        {
          label: 'Stock',
          field: 'stock_status',
          formatter: (row) => row.stock_status && startCase(row.stock_status),
        },
        {
          label: 'Weight',
          field: 'weight',
          formatter: (row) => (
            <span>
              {row.weight}&nbsp;
              <span className="small text-muted">
                {row.weight && row.weight > 1 ? '(lbs.)' : '(lb.)'}
              </span>
            </span>
          ),
        },
        {
          label: 'Dimensions',
          field: 'dimensions',
          formatter: ({ dimensions }) => (
            <>
              {`${dimensions?.length}x${dimensions?.width}x${dimensions?.height}`}
              <small className="text-muted">&nbsp;(in.)</small>
            </>
          ),
        },
        {
          label: 'Price',
          field: 'price',
          formatter: (row) => formatToCurrency(row.price),
        },
      ]}
    />
  );
}
