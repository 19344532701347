import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { ReactNode } from 'react';
import { Alert } from 'react-bootstrap';

export interface InfoBoxProps {
  content: ReactNode;
}

export function InfoBox(props: InfoBoxProps) {
  return (
    <Alert variant="primary" className="d-flex">
      <div className="me-3">
        <FontAwesomeIcon icon={faLightbulb} className="" />
      </div>
      <div className="flex-grow-1"> {props.content}</div>
    </Alert>
  );
}
