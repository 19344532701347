import {
  LazyQueryResultTuple,
  OperationVariables,
  QueryResult,
} from '@apollo/client';
import { faFilter, faRefresh } from '@fortawesome/free-solid-svg-icons';
import { Row } from '@tanstack/react-table';
import { bind, concat, filter, includes, join, map, split } from 'lodash';
import {
  type ReactNode,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { Alert, Container } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';

import { Pagination } from '../../components/pagination/pagination';
import Button, { ButtonProps } from '../button/button';
import { DropdownMultiSelect } from '../dropdown-multi-select/dropdown-multi-select';
import { Loading } from '../loading/loading';
import { type ColumnProps, Table } from '../table/table';

export interface PaginationFilter {
  label: string;
  value: string;
  isActive?: boolean;
}

export interface PaginationResponse<D> {
  count?: number | null;
  pageInfo: {
    currentPage: number;
    perPage: number;
    pageCount?: number | null;
    itemCount?: number | null;
    hasNextPage?: boolean | null;
    hasPreviousPage?: boolean | null;
  };
  items?: D[] | null;
}

export interface PaginationTableProps<
  TData,
  TAccessor extends PaginationResponse<TData>,
  TQuery,
  TVars extends OperationVariables,
  TSort,
> {
  actionButtons?: (t: QueryResult<TQuery, TVars>) => ButtonProps[];
  dataAccessor: (r: TQuery) => TAccessor | undefined | null;
  columns: ColumnProps<TData, TQuery, TVars>[];
  filters?: PaginationFilter[];
  defaultFilters?: string[];
  defaultSort: TSort;
  buildFilterQuery: (
    allFilters: PaginationFilter[],
    sort: TSort,
    page: number,
    perPage: number,
  ) => TVars | undefined;
  paginationQuery: LazyQueryResultTuple<TQuery, TVars>;
  renderSubComponent?: (
    row: Row<TData>,
    operation?: QueryResult<TQuery, TVars>,
  ) => ReactNode;
}

export function PaginationTable<
  TData,
  TAccessor extends PaginationResponse<TData>,
  TQuery,
  TVars extends OperationVariables,
  TSort,
>({
  actionButtons,
  dataAccessor,
  buildFilterQuery,
  columns,
  defaultFilters,
  defaultSort,
  filters,
  paginationQuery,
  renderSubComponent,
}: PaginationTableProps<TData, TAccessor, TQuery, TVars, TSort>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [loadData, operation] = paginationQuery;

  const accessor = useMemo(
    () => operation.data && dataAccessor(operation.data),
    [dataAccessor, operation.data],
  );

  const [page, setPage] = useState<number>(1);
  const [perPage] = useState<number>(15);
  const [pageCount, setPageCount] = useState<number>(1);
  const [itemCount, setItemCount] = useState<number>(10);

  const buildFilters = useCallback(
    (selectedFilters: string[]) =>
      map(filters, (f) => ({
        ...f,
        isActive: includes(selectedFilters, f.value),
      })),
    [filters],
  );

  const [allFilters, setAllFilters] = useState<PaginationFilter[]>(
    buildFilters(defaultFilters || []),
  );

  const handleSetPage = useCallback(
    (newPage: number) => {
      setPage(newPage);
      searchParams.set('page', newPage.toString());
      operation.refetch(
        buildFilterQuery(allFilters, defaultSort, newPage, perPage),
      );
      setSearchParams(searchParams);
    },
    [
      allFilters,
      buildFilterQuery,
      defaultSort,
      operation,
      perPage,
      searchParams,
      setSearchParams,
    ],
  );

  useEffect(() => {
    const pageParam = searchParams.get('page');
    const filterParam = searchParams.get('filter');

    if (pageParam) {
      setPage(parseInt(pageParam));
    } else {
      searchParams.set('page', page.toString());
    }

    if (filters) {
      if (filterParam) {
        setAllFilters(buildFilters(split(filterParam, ',')));
      } else {
        searchParams.set(
          'filter',
          join(
            map(
              filter(allFilters, (f) => !!f.isActive),
              (m) => m.value,
            ),
            ',',
          ),
        );
      }
    }

    setSearchParams(searchParams);

    if (!operation.loading && !operation.called && !operation.data) {
      console.log('initial load');
      loadData({
        variables: buildFilterQuery(allFilters, defaultSort, page, perPage),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    operation.loading,
    operation.called,
    operation.data,
    searchParams,
    setSearchParams,
  ]);

  useEffect(() => {
    if (operation.data) {
      if (accessor?.pageInfo.itemCount) {
        setItemCount(accessor.pageInfo.itemCount);
      }
      if (accessor?.pageInfo.pageCount) {
        setPageCount(accessor.pageInfo.pageCount);
      }
    }
  }, [accessor, operation, operation.data]);

  if (operation.loading) {
    return <Loading />;
  }

  return (
    <Container>
      <div className="d-flex justify-content-end gap-2 my-3">
        {map(
          concat(actionButtons ? actionButtons(operation) : [], {
            content: <span className="d-none d-md-inline">Refresh</span>,
            icon: faRefresh,
            isLoading: operation.loading,
            onClick: () => operation.refetch(),
          }),
          (b, idx) => (
            <Button key={idx} {...b} />
          ),
        )}
        {filters && (
          <DropdownMultiSelect
            title="Filters"
            options={allFilters}
            content="Apply"
            icon={faFilter}
            onApply={(options) => {
              searchParams.set('filter', join(options, ','));
              setSearchParams(searchParams);

              return operation.refetch(
                buildFilterQuery(
                  buildFilters(options),
                  defaultSort,
                  page,
                  perPage,
                ),
              );
            }}
          />
        )}
      </div>
      {operation.error && (
        <Alert variant="danger">
          <pre>
            <code>{JSON.stringify(operation.error, null, 2)}</code>
          </pre>
        </Alert>
      )}
      {accessor?.items && (
        <Table<TData, TQuery, TVars>
          rows={accessor.items}
          rowAlignment="middle"
          renderSubComponent={renderSubComponent}
          columns={map(columns, (c) => {
            if (c.formatter) {
              c.formatter = bind(
                c.formatter,
                c,
                bind.placeholder,
                bind.placeholder,
                operation,
              );
            }
            return c;
          })}
        />
      )}
      <div className="d-flex justify-content-center mt-2">
        <Pagination
          setPage={handleSetPage}
          currentPage={page}
          pageCount={pageCount}
          itemCount={itemCount}
        />
      </div>
    </Container>
  );
}
