import { faCheck, faRefresh, faUndo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone, filter, map } from 'lodash';
import { useReducer, useState } from 'react';
import { Dropdown, Stack } from 'react-bootstrap';

import Button, { type ButtonProps } from '../button/button';

interface Option {
  label: string;
  value: string;
  isActive?: boolean;
}

export interface DropdownMultiSelectProps
  extends Pick<ButtonProps, 'content' | 'icon'> {
  title: string;
  options: Option[];
  onApply: (options: string[]) => void;
}

function filterReducer(
  allFilters: Option[],
  action:
    | { type: 'add_option' | 'remove_option'; idx: number }
    | { type: 'reset'; initialState: Option[] },
): Option[] {
  switch (action.type) {
    case 'add_option':
      allFilters[action.idx].isActive = true;
      break;
    case 'remove_option':
      allFilters[action.idx].isActive = false;
      break;
    case 'reset':
      allFilters = action.initialState;
      break;

    default:
      throw Error('Unknown action.');
  }

  // cloning to trigger state change
  return clone(allFilters);
}

export function DropdownMultiSelect(props: DropdownMultiSelectProps) {
  const [initialState] = useState(props.options);

  const [shown, setShown] = useState<boolean>(false);
  const [allFilters, dispatchFilterAction] = useReducer(
    filterReducer,
    props.options,
  );

  return (
    <Dropdown className="d-inline" show={shown}>
      <Dropdown.Toggle onClick={() => setShown(!shown)}>
        {props.icon && (
          <FontAwesomeIcon icon={props.icon} className="me-1 me-md-2" />
        )}
        <span className="d-none d-md-inline">{props.title}</span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {map(allFilters, (option, idx) => (
          <Dropdown.Item
            key={idx}
            onClick={() => {
              dispatchFilterAction({
                type: option.isActive ? 'remove_option' : 'add_option',
                idx,
              });
            }}
          >
            <div className="d-flex align-items-center">
              <div style={{ minWidth: '25px' }}>
                {option.isActive && (
                  <FontAwesomeIcon
                    icon={faCheck}
                    className="ms-1 text-primary"
                  />
                )}
              </div>
              {option.label}
            </div>
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <Stack
          direction="horizontal"
          gap={2}
          className="justify-content-center"
        >
          <Button
            content="Reset"
            variant="ghost"
            icon={faUndo}
            size="sm"
            onClick={() => {
              dispatchFilterAction({
                type: 'reset',
                initialState,
              });
            }}
          />
          <Button
            content={props.content}
            icon={props.icon}
            size="sm"
            onClick={() => {
              props.onApply(
                map(
                  filter(allFilters, (f) => !!f.isActive),
                  (m) => m.value,
                ),
              );
              setShown(false);
            }}
          />
        </Stack>
      </Dropdown.Menu>
    </Dropdown>
  );
}
