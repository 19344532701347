import {
  DataDetailList,
  type DataDetailListProps,
} from '@farmshare/ui-components';
import {
  formatToCurrency,
  formatToShortDate,
  isArrayNullOrEmpty,
} from '@farmshare/utils';
import { map, startCase } from 'lodash';
import moment from 'moment';
import { useCallback } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import type { Shipment, ShipmentPickup } from 'lib/graphql';
import { addressFieldsArray } from 'lib/helpers';

interface ShipmentInformationProps {
  shipment: Shipment;
}

export function ShipmentInformation({ shipment }: ShipmentInformationProps) {
  const pickupValuesArray = useCallback((pickup?: ShipmentPickup | null) => {
    const arr: DataDetailListProps['rows'] = [];

    // if (pickup) {
    arr.push({
      label: 'Confirmation',
      value: pickup?.confirmation_number,
    });
    arr.push({
      label: 'Notes',
      value: pickup?.pickup_notes,
    });

    if (!isArrayNullOrEmpty(pickup?.pickup_windows)) {
      arr.push({
        label: 'Windows',
        value: (
          <div>
            {map(
              pickup?.pickup_windows,
              (window) =>
                window && (
                  <div key={window._id}>
                    {moment.utc(window.start_at).format('MM/DD LT')}-
                    {moment.utc(window.end_at).format('LT')}
                  </div>
                ),
            )}
          </div>
        ),
      });
    }
    if (!isArrayNullOrEmpty(pickup?.pickup_errors)) {
      arr.push({
        label: 'Errors',
        value: (
          <ol>
            {map(
              pickup?.pickup_errors,
              (error) =>
                error && (
                  <li key={error._id} className="small text-danger">
                    {error.message}
                  </li>
                ),
            )}
          </ol>
        ),
      });
    }
    // }

    return arr;
  }, []);

  return (
    <Container>
      <Row>
        {shipment.vendor && (
          <Col md={4}>
            <DataDetailList
              heading="Vendor"
              rows={addressFieldsArray(shipment.vendor.address, false, false)}
            />
          </Col>
        )}
        <Col md={4}>
          <DataDetailList
            heading="Details"
            rows={[
              {
                label: 'Status',
                value: startCase(shipment.shipment_status),
              },
              {
                label: 'Ship Date',
                value: formatToShortDate(shipment.ship_date),
              },
              {
                label: 'Service',
                value: shipment.chosen_rate?.service_type,
              },
              {
                label: 'Delivery Days',
                value: shipment.chosen_rate?.delivery_days,
              },
              {
                label: 'Requested Delivery Date',
                value: formatToShortDate(shipment.requested_delivery_date),
              },
              {
                label: 'Estimated Delivery',
                value: formatToShortDate(
                  shipment.chosen_rate?.estimated_delivery_date,
                ),
              },
              {
                label: 'Shipping Cost',
                value: formatToCurrency(shipment.chosen_rate?.total_amount),
              },
            ]}
          />
        </Col>
        <Col md={4}>
          <DataDetailList
            heading="Pickup"
            rows={pickupValuesArray(shipment?.pickup)}
          />
        </Col>
      </Row>
    </Container>
  );
}
