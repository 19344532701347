import { ExternalLink, PaginationTablePage } from '@farmshare/ui-components';
import { isArrayNullOrEmpty } from '@farmshare/utils';
import { filter, first, map, startCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  type Shipment,
  SortFindManyShipmentInput,
  EnumShipmentShipment_Status,
  useShipmentPaginationLazyQuery,
  ShipmentPagination,
  ShipmentPaginationQueryVariables,
  ShipmentPaginationQuery,
} from 'lib/graphql';

export default function Shipments() {
  return (
    <PaginationTablePage<
      Pick<
        Shipment,
        | '_id'
        | 'packages'
        | 'vendor'
        | 'order'
        | 'shipment_status'
        | 'chosen_rate'
        | 'pickup'
        | 'requested_delivery_date'
      >,
      ShipmentPagination,
      ShipmentPaginationQuery,
      ShipmentPaginationQueryVariables,
      SortFindManyShipmentInput
    >
      title="Shipments"
      paginationQuery={useShipmentPaginationLazyQuery()}
      buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
        const activeFilters = filter(allFilters, (f) => f.isActive || false);

        if (activeFilters) {
          return {
            filter: {
              OR: map(activeFilters, (a) => ({
                shipment_status: a.value as EnumShipmentShipment_Status,
              })),
            },
            sort: defaultSort,
            page,
            perPage,
          };
        }
        return { sort: defaultSort, page, perPage };
      }}
      filters={map(EnumShipmentShipment_Status, (e) => ({
        label: e.toString(),
        value: e,
      }))}
      defaultFilters={[
        EnumShipmentShipment_Status.New,
        EnumShipmentShipment_Status.LabelPurchased,
        EnumShipmentShipment_Status.PickupScheduled,
        EnumShipmentShipment_Status.WaitingForDropoff,
        EnumShipmentShipment_Status.InTransit,
      ]}
      defaultSort={SortFindManyShipmentInput.DeliveryDateAsc}
      dataAccessor={(a) => a.shipmentPagination as ShipmentPagination}
      columns={[
        {
          label: 'Order',
          formatter: (row) => (
            <Link to={`/orders/${row.order?.external_id}#${row._id}`}>
              {row.order?.external_id}
            </Link>
          ),
        },
        {
          label: 'Tracking Page',
          formatter: (row) => {
            const firstPackage = first(row.packages);
            if (firstPackage?.tracking_page && firstPackage.tracking_number) {
              return (
                <ExternalLink href={firstPackage.tracking_page} text="Link" />
              );
            }
          },
        },
        { label: 'Vendor', field: 'vendor.shop_name' },
        {
          label: 'Status',
          formatter: (row) => startCase(row.shipment_status),
        },
        { label: 'Carrier', field: 'chosen_rate.service_type' },
        {
          label: 'Days',
          field: 'chosen_rate.delivery_days',
          minimumBreakpoint: 'xl',
        },
        {
          label: 'Pickup Date',
          formatter: (row) =>
            row.pickup?.pickup_windows &&
            !isArrayNullOrEmpty(row.pickup?.pickup_windows) ? (
              moment(first(row.pickup.pickup_windows)?.start_at).format('L')
            ) : (
              <span className="text-muted fst-italic">n/a</span>
            ),
        },
        {
          label: 'Requested Date',
          formatter: (row) => moment(row.requested_delivery_date).format('L'),
        },
        {
          label: 'Expected Date',
          formatter: (row) => (
            <div className="d-none d-md-block">
              {row.chosen_rate?.estimated_delivery_date ? (
                moment(row.chosen_rate?.estimated_delivery_date).format('L')
              ) : (
                <span className="fst-italic text-muted">tbd...</span>
              )}
            </div>
          ),
          minimumBreakpoint: 'xxl',
        },
      ]}
    />
  );
}
