import ThemeSelector, {
  type Theme,
} from '../../components/theme-selector/theme-selector';

export interface SiteFooterProps {
  theme: Theme;
  setTheme: (theme: Theme) => void;
}

export function SiteFooter(props: SiteFooterProps) {
  return (
    <div className="position-fixed bottom-0 start-0 bg-body px-2 py-1 ms-3 my-2 rounded-3">
      <ThemeSelector theme={props.theme} setTheme={props.setTheme} />
    </div>
  );
}

export default SiteFooter;
