import {
  InputText,
  PaginationTablePage,
  useModal,
} from '@farmshare/ui-components';
import { formatToCurrency } from '@farmshare/utils';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { filter, map, startCase } from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';

import {
  EnumOrderStatus,
  Order,
  OrderPagination,
  OrderPaginationQuery,
  OrderPaginationQueryVariables,
  SortFindManyOrderInput,
  useOrderImportMutation,
  useOrderPaginationLazyQuery,
} from 'lib/graphql';

interface OrderImportModal {
  orderId?: number;
}

export default function Orders() {
  const { save } = useModal();

  const [importOrder, importOrderMutation] = useOrderImportMutation();

  return (
    <PaginationTablePage<
      Order,
      OrderPagination,
      OrderPaginationQuery,
      OrderPaginationQueryVariables,
      SortFindManyOrderInput
    >
      actionButtons={({ loading, refetch }) => [
        {
          content: 'Import Order',
          icon: faFileImport,
          isLoading: importOrderMutation.loading,
          onClick: () =>
            save<OrderImportModal>({
              type: 'save',
              title: 'Order Import',
              saveText: 'Import',
              saveIcon: faFileImport,
              icon: faFileImport,
              isLoading: loading,
              body: () => <InputText type="number" label="Order Id" required />,
              async onSubmit({ orderId }: OrderImportModal) {
                if (orderId) {
                  await importOrder({
                    variables: { orderId },
                  });
                  await refetch();
                }
              },
              initialValues: { orderId: undefined },
            }),
        },
      ]}
      title="Orders"
      filters={map(EnumOrderStatus, (e) => ({
        label: e.toString(),
        value: e,
      }))}
      buildFilterQuery={(allFilters, defaultSort, page, perPage) => {
        const activeFilters = filter(allFilters, (f) => f.isActive || false);

        if (activeFilters) {
          return {
            filter: {
              OR: map(activeFilters, (a) => ({
                status: a.value as EnumOrderStatus,
              })),
            },
            sort: defaultSort,
            page,
            perPage,
          };
        }
        return { sort: defaultSort, page, perPage };
      }}
      defaultFilters={[EnumOrderStatus.Pending, EnumOrderStatus.Processing]}
      defaultSort={SortFindManyOrderInput.OrderNumberDesc}
      dataAccessor={(a) => a.orderPagination as OrderPagination}
      paginationQuery={useOrderPaginationLazyQuery()}
      columns={[
        {
          label: 'Id',
          field: 'external_id',
          formatter: (row) => (
            <Link to={`/orders/${row.external_id}`}>{row.external_id}</Link>
          ),
        },
        {
          label: 'Date Created',
          field: 'date_created',
          formatter: (row) => moment(row.date_created).format('LLL'),
        },
        {
          label: 'Status',
          field: 'status',
          formatter: (row) => startCase(row.status),
        },
        { label: 'Customer', field: 'customer.full_name' },
        {
          label: 'Items',
          field: 'line_items',
          formatter: (row) => row.line_items?.length,
        },
        {
          label: 'Total',
          field: 'total',
          formatter: (row) => formatToCurrency(row.total),
        },
      ]}
    />
  );
}
